import React, { useEffect } from 'react'

import { data } from '../../Data/infoVerticalPage'
import { useParams } from 'react-router';
import 'react-multi-carousel/lib/styles.css';
import Video from '../../Media/almacenVideo.mp4'
import TiktokCarousel from '../Elements/TiktokCarousel';

const videos = [
  Video,
  Video,
  Video,
  Video,
  Video,
  Video
]

export default function VerticalInfoPage() {

  const {type} = useParams();
  const {banner, mision, values, solutions} =  data[type];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [banner])

  return (
    <div>

      {/* HERO SECTION */}
        <div className="relative isolate -z-10 overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
          <div
            className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
            aria-hidden="true"
          />
            <div
            className="absolute right-0 top-0 -z-20 h-full w-full bg-gradient-to-r from-indigo-50 from-60%
            lg:w-10/12 lg:from-50%
            xl:w-2/3">
                <img
                src={banner.imgBackgoundBanner}
                alt=""
                className="w-full h-full object-cover mix-blend-overlay"
              />
            </div>
          <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
              <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
                  {banner.title}
              </h1>
              <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                <p className="text-lg leading-8 text-gray-600">
                  {banner.infoSubtitle}
                </p>
              </div>
              <img
                src={banner.imgBanner}
                alt=""
                className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
              />
              
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
        </div>


        {/* INFORMACIÓN */}
        <div className="mx-auto mt-20 sm:mt-28 max-w-7xl px-6 sm:mt-0 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{mision.title}</h2>
            <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
              <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                <p className="text-xl leading-8 text-gray-600">
                   {mision.paragraph1}
                </p>
                <div className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                  <p>
                    {mision.paragraph2}
                  </p>
                  <p className="mt-10">
                    {mision.paragraph3}
                  </p>
                </div>
              </div>
              {/* <div className="lg:flex lg:flex-auto lg:justify-center">
                <dl className="w-64 space-y-8 xl:w-80">
                  {mision.stats.map((stat) => (
                    <div key={stat.label} className="flex flex-col-reverse gap-y-4">
                      <dt className="text-base leading-7 text-gray-600">{stat.label}</dt>
                      <dd className="text-5xl font-semibold tracking-tight text-gray-900">{stat.value}</dd>
                    </div>
                  ))}
                </dl>
              </div> */}
            </div>
          </div>
        </div>


        {/* TIKTOKS */}
        {/* <TiktokCarousel videos={videos}/> */}


        {/* BANNER */}
        <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8">
          <img
            src={mision.img}
            alt=""
            className="aspect-[9/4] w-full object-cover xl:rounded-3xl"
          />
        </div>


        {/* BULLETS */}
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8 mb-20">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-black sm:text-4xl">{values.title}</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
                {values.subtitle}
            </p>
          </div>
          <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
            {values.infoValues.map((value) => (
              <div key={value.name} className="relative pl-9">
                <dt className="inline font-semibold text-black">
                  <value.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                  {value.name}
                </dt>{' '}
                <dd className="inline">{value.description}</dd>
              </div>
            ))}
          </dl>
        </div>


        {/* SOLUCIONES */}
        {/* <div className="p-2 box-border mx-auto max-w-7xl mt-32 px-6 sm:mt-40 lg:px-8 mb-4">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-black sm:text-4xl text-slate-950">{solutions.title} </h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
                {solutions.subtitle}
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-5 mb-5 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-14 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4 xl:grid-cols-6"
          >
            {
              solutions.infoCards.map(card => {
                return(
                  <img key={card.title} className="aspect-[14/13] w-full rounded-2xl object-cover" src={card.img} alt={card.title} />
                )
              })
            }
          </ul>
        </div> */}

    </div>
  )
}
