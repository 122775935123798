import FotoAlmac from '../Media/almacen.jpg'
import FotoManu from '../Media/manufactura.jpg'
import FotoRetail from '../Media/retail.jpg'
import FotoTransp from '../Media/transporte.jpg'
import FotoHotel from '../Media/hotel.jpg'

export const data = {
    section: [
        {
            title: 'Almacenes',
            description: ['Mejoras en la eficiencia, precisión y rentabilidad en la gestión de tu inventario y logística.', <div>Buscamos maximizar el espacio, agilizar la recepción, el almacenamiento y la preparación de pedidos, mejorar la visibilidad del inventario, reducir errores y costos operativos, y garantizar que los productos se entreguen de manera eficiente a los clientes.</div>], 
            image: FotoAlmac, 
            bgColor: 'bg-amber-50',
            gradient: 'rgba(245, 237, 208, 1)',
            value: 'warehouses'
        },{
            title: 'Manufactura',
            description: 'Optimizar la efectividad de las operaciones de fabricación, así como en mejorar la trazabilidad y rastreabilidad de los materiales y productos en toda la cadena de suministro, resultando en una producción más controlada, productos de mejor calidad y una mayor capacidad para cumplir con regulaciones y estándares de calidad.', 
            image: FotoManu, 
            bgColor: 'bg-rose-50',
            gradient: 'rgba(255, 226, 216, 1)',
            value: 'manufacture'
        },{
            title: 'Retail',
            description: 'Mayor satisfacción en la experiencia del cliente y optimizar las operaciones en el sector minorista. Trabajamos para lograr que los minoristas puedan adaptarse a las cambiantes preferencias de compra de los consumidores, ofrecer un servicio más eficiente y personalizado, y mantener una ventaja competitiva en un entorno altamente dinámico y digital.', 
            image: FotoRetail, 
            bgColor: 'bg-orange-50',
            gradient: 'rgba(245, 223, 203, 1)',
            value: 'retail'
        },{
            title: 'Transporte & Logística',
            description: 'Modernización en la gestión y operación de las cadenas de suministro y transporte. Nuestro objetivo es mejorar la eficacia, visibilidad y toma de decisiones en las operaciones de transporte y logística, permitiendo una gestión más ágil y rentable de los bienes, asegurando que lleguen a su destino de manera oportuna y eficaz', 
            image: FotoTransp, 
            bgColor: 'bg-amber-100',
            gradient: 'rgba(229, 216, 183, 1)',
            value: 'transport'
        },{
            title: 'Hospitalidad',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam a aliquam ex. Curabitur egestas erat est, sit amet mollis felis aliquet at. Maecenas sollicitudin imperdiet nunc eu molestie. Suspendisse massa erat, tempus viverra consequat ac, sagittis et magna.', 
            image: FotoHotel, 
            bgColor: 'bg-orange-50',
            gradient: 'rgba(245, 237, 211, 1)',
            value: 'hospitality'
        }
    ]
}