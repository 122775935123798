import { ChevronDownIcon } from '@heroicons/react/24/solid'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function ServiceTabLanding({linkRef, clicked, onClick, bgColor, img, title, text}) {

  return (

    <div className={`${clicked == false ? 'pb-0' : 'pb-7 sm:pb-8'} ${title == "Software" ? 'border-b-2' : ''} border-t-2 border-zinc-200 flex flex-col`}>
        <div 
        onClick={onClick}
        className={`${clicked == false ? 'pb-7 sm:pb-8 cursor-pointer' : 'pb-4 cursor-default'} w-full text-lg pt-7 font-normal 
        sm:pt-10 sm:font-bold`}>
          {title}
          <ChevronDownIcon className="h-8 w-8 text-gray-700 float-right" />
        </div>

        <div hidden = {clicked == false} className='relative text-sm
        md:text-base text-gray-600'>
          <div className={`absolute sm:hidden top-0 mt-[10px] scale-y-[1.1] scale-x-[1.25] bg-gradient-to-t ${bgColor} w-full h-full -z-10`}>
          </div>

          <div 
          className='w-full pb-4 leading-7
          sm:leading-8
          lg:w-5/6 lg:leading-9'>
          {text}
          </div>

          <Link to={"/services"} state={linkRef}>
            <div 
            className='text-cyan-400 underline underline-offset-4'>
            Obtener más información
            </div>
          </Link>

          <div className="sm:hidden w-full scale-x-[1.25] mt-8">
              <img src={img}
              id='foto'
              className="w-full h-full object-cover"/>
          </div>
        </div>
    </div>

  )
}
