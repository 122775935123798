import React from 'react'
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'

export default function PrivacyNoticePage() {
  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-5xl text-base leading-7 text-gray-700">
        <h1 className="mt-2 text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Aviso de Privacidad</h1>
        {/* <p className="mt-6 text-xl leading-8">
          A
        </p> */}
        <div className="mt-10 max-w-5xl">

            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Para clientes .-</h2>
          <div className='mt-6 text-justify'>
            <div className='indent-8'>
                VISIÓN TEC, S.A. DE C.V., con domicilio en la calle San Lorenzo No. 20, Col. Vista Hermosa CP. 64620 en Monterrey, N.L., es una sociedad mercantil debidamente constituida bajo las Leyes que rigen en México y especialmente de la Ley General de Sociedades Mercantiles vigente en el País, es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección conforme a los artículo 8, 15, 16, 17, 33 y 36 de la Ley Federal de Protección de Datos Personales.
            </div>
            <div className='mt-4 indent-8'>
                Su información personal será utilizada para promover los servicios y productos que ha solicitado, a través de nuestra página de Internet, así como para en su caso enviarle boletines, noticias y promociones de relevancia en relación a los temas sobre los que verse su servicio u operación comercial requerido, ofrecerle nuestros productos y/o servicios, seguimiento, tramitación y gestión de pagos, así como solución respecto a las órdenes de compra celebradas, evaluar la calidad de nuestros servicios, así como para cualquier otro fin estrictamente relacionado con servicios que podamos llegar a prestarle como sociedad mercantil, los cuales de forma enunciativa más no limitativa se han listado en el párrafo anterior el cual se reproduce nuevamente en este acto, en general para contactarlo e informarle de los avances en los servicios u operaciones comerciales que se nos encomienden, realizar estudios internos sobre necesidades de los clientes.
            </div>
            <div className='mt-4 indent-8'>
                Para las finalidades antes descritas en este Aviso de Privacidad, podemos recabar y recibir sus datos personales por cualquier medio electrónico, óptico, sonoro, visual, o a través de cualquier otra tecnología, ya sea: (i) directamente, cuando usted los proporciona en su domicilio u oficina, así como en las oficinas de VISIÓN TEC, S.A. DE C.V., o algún miembro de esta los solicite por cualquier medio ya sea por teléfono, por mensaje electrónico o mensajes de cualquier tipo; (ii) a través de nuestra página de Internet, o (iii) a través de otras fuentes permitidas por la ley, tales como los directorios telefónicos o laborales. Los datos personales que obtenemos por estos medios pueden ser en forma enunciativa pero no limitativa. Usted tiene derecho de acceder, rectificar y cancelar sus datos personales, así como de oponerse al tratamiento de los mismos o revocar el consentimiento que para tal fin nos haya otorgado, a través de los procedimientos que hemos implementado. Para conocer dichos procedimientos. Los requisitos y plazos, se puede poner en contacto con nuestro departamento de Área de Privacidad, al teléfono 8181294348, avisoprivacidad@visiontec.com.mx o visitar nuestra página de internet <Link to="https://visiontec.com.mx/" target="_blank" rel="noreferrer" className='text-sky-400 hover:underline hover:text-sky-300'>www.visiontec.com.mx</Link>, con horario de recepción de 10:00 diez horas a las 13:00 trece horas y de 16:00 dieciséis a 18:00 dieciocho horas.
            </div>
            <div className='mt-4 indent-8'>
                Igualmente se le hace saber que, si lo desea es su derecho oponerse respecto al tratamiento de sus datos, para el objeto de limitar su uso o divulgación de los mismos, para lo cual deberá realizar su solicitud de negativa vía correo electrónico a la dirección de avisoprivacidad@visiontec.com.mx, esto para su debido conocimiento y registro correspondiente.
            </div>
            <div className='mt-4 indent-8'>
                Asimismo, le informamos que sus datos personales pueden ser transferidos y tratados dentro y fuera del país, por terceras personas distintas a esta empresa. En ese sentido. Nos comprometemos a no transferir su información personal a terceros sin su consentimiento, salvo las excepciones previstas en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, así como a realizar esta transferencia en los términos que fija esa ley.
            </div>
            <div className='mt-4 indent-8'>
                Si usted no manifiesta su oposición para que sus datos personales sean transferidos, se entenderá que ha otorgado su consentimiento para ello. Si usted desea dejar de recibir mensajes promocionales de nuestra parte puede solicitarlo a VISIÓN TEC, S.A. DE.C.V.
            </div>
          </div>


          <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900">Medios y procedimientos para ejercer los derechos ARCO</h2>
          <div className='mt-6 text-justify'>
            <div className='indent-8'>
                En todo momento, usted podrá revocar el consentimiento que nos ha otorgado para el tratamiento de sus datos personales, a fin de que dejemos de hacer uso de los mismos. Para ello es necesaria su petición poniéndose en contacto con VISIÓN TEC, S.A. DE C.V. a través del correo electrónico avisoprivacidad@visiontec.com.mx y se le estará contactando a través del correo electrónico con el cual haya enviado su solicitud, en un plazo máximo de 15 días hábiles para atender su petición y así le informaremos el resultado de la misma.
            </div>
            <div className='mt-4 indent-8'>
                Cualquier modificación de este aviso de privacidad podrá consultarla en www.visiontec.com.mx es responsabilidad de las personas que nos hagan llegar información estar al pendiente de la misma para informarse acerca de los cambios.
            </div>
            <div className='mt-4 indent-8'>
                El envío de los datos personales por cualquier persona utilizando cualquier medio electrónico, óptico, sonoro, visual, o a través de cualquier otra tecnología, implica la aceptación y autorización de dicha persona a la forma en que VISIÓN TEC trata dichos datos personales de conformidad con el presente Aviso de Privacidad.
            </div>
            <div className='mt-4 indent-8 text-gray-400'>
                Fecha de última actualización 17 de Octubre de 2014.
            </div>
        </div>

        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Para Proveedores .-</h2>
          <div className='mt-6 text-justify'>
            <div className='indent-8'>
                VISIÓN TEC, S.A. DE C.V., con domicilio en la calle San Lorenzo No. 20, Col. Vista Hermosa CP. 64620 en Monterrey, N.L., es una sociedad mercantil debidamente constituida bajo las Leyes que rigen en México y especialmente de la Ley General de Sociedades Mercantiles vigente en el País, es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección conforme a los artículo 8, 15, 16, 17, 33 y 36 de la Ley Federal de Protección de Datos Personales.
            </div>
            <div className='mt-4 indent-8'>
                Su información personal será utilizada para adquirir cuando Visión Tec, S.A. DE C.V. lo requiera, los servicios y productos que promociona y ofrece su persona, solicitarle lista de precios y promociones existentes, así como para en su caso enviarle boletines, noticias, requerimientos y solicitudes en relación a los temas sobre los que verse su servicio u operación comercial, evaluar la calidad del servicio, así como para cualquier otro fin estrictamente relacionado con los requerimientos comerciales que VISIÓN TEC, S.A. DE C.V., pueda necesitar de su persona en relación a su actividad u objeto social que desempeña; y, en general para contactarlo e informarle de las circunstancias e incidencias que se den respecto a los servicios u operaciones comerciales que se celebren.
            </div>
            <div className='mt-4 indent-8'>
                Para las finalidades antes descritas en este Aviso de Privacidad, podemos recabar y recibir sus datos personales por cualquier medio electrónico, óptico, sonoro, visual, o a través de cualquier otra tecnología, ya sea: (i) directamente, cuando usted los proporciona en su domicilio u oficina, así como en las oficinas de VISIÓN TEC, S.A. DE C.V., o algún miembro de esta los solicite por cualquier medio ya sea por teléfono, por mensaje electrónico o mensajes de cualquier tipo; (ii) a través de nuestra página de Internet, o (iii) a través de otras fuentes permitidas por la ley, tales como los directorios telefónicos o laborales. Los datos personales que obtenemos por estos medios pueden ser en forma enunciativa pero no limitativa. Usted tiene derecho de acceder, rectificar y cancelar sus datos personales, así como de oponerse al tratamiento de los mismos o revocar el consentimiento que para tal fin nos haya otorgado, a través de los procedimientos que hemos implementado. Para conocer dichos procedimientos. Los requisitos y plazos, se puede poner en contacto con nuestro departamento de Área de Privacidad, al teléfono 8181294348 o electrónico avisoprivacidad@visiontec.com.mx o visitar nuestra página de internet www.visiontec.com.mx, con horario de recepción de 10:00 diez horas a las 13:00 trece horas y de 16:00 dieciséis a 18:00 dieciocho horas.
            </div>
            <div className='mt-4 indent-8'>
                Igualmente se le hace saber que, si lo desea es su derecho oponerse respecto al tratamiento de sus datos, para el objeto de limitar su uso o divulgación de los mismos, para lo cual deberá realizar su solicitud de negativa vía correo electrónico a la dirección de avisoprivacidad@visiontec.com.mx, esto para su debido conocimiento y registro correspondiente.
            </div>
            <div className='mt-4 indent-8'>
                Asimismo, le informamos que sus datos personales pueden ser transferidos y tratados dentro y fuera del país, por terceras personas distintas a esta empresa. En ese sentido. Nos comprometemos a no transferir su información personal a terceros sin su consentimiento, salvo las excepciones previstas en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, así como a realizar esta transferencia en los términos que fija esa ley.
            </div>
            <div className='mt-4 indent-8'>
                Si usted no manifiesta su oposición para que sus datos personales sean transferidos, se entenderá que ha otorgado su consentimiento para ello. Si usted desea dejar de recibir mensajes promocionales de nuestra parte puede solicitarlo a VISIÓN TEC, S.A. DE.C.V.
            </div>
        </div>

        <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900">Medios y procedimientos para ejercer los derechos ARCO</h2>
          <div className='mt-6 text-justify'>
            <div className='indent-8'>
                En todo momento, usted podrá revocar el consentimiento que nos ha otorgado para el tratamiento de sus datos personales, a fin de que dejemos de hacer uso de los mismos. Para ello es necesaria su petición poniéndose en contacto con VISIÓN TEC, S.A. DE C.V. a través del correo electrónico avisoprivacidad@visiontec.com.mx y se le estará contactando a través del correo electrónico con el cual haya enviado su solicitud, en un plazo máximo de 15 días hábiles para atender su petición y así le informaremos el resultado de la misma.
            </div>
            <div className='mt-4 indent-8'>
                Cualquier modificación de este aviso de privacidad podrá consultarla en www.visióntec.com.mx es responsabilidad de las personas que nos hagan llegar información estar al pendiente de la misma para informarse acerca de los cambios.
            </div>
            <div className='mt-4 indent-8'>
                El envío de los datos personales por cualquier persona utilizando cualquier medio electrónico, óptico, sonoro, visual, o a través de cualquier otra tecnología, implica la aceptación y autorización de dicha persona a la forma en que VISIÓN TEC trata dichos datos personales de conformidad con el presente Aviso de Privacidad.
            </div>
            <div className='mt-4 indent-8 text-gray-400'>
                Fecha de última actualización 17 de Octubre de 2014.
            </div>
        </div>

        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Para Candidatos en el Proceso De Reclutamiento y Selección de personal .-</h2>
          <div className='mt-6 text-justify'>
            <div className='indent-8'>
                VISIÓN TEC, S.A. DE C.V., con domicilio en la calle San Lorenzo No. 20, Col. Vista Hermosa CP. 64620 en Monterrey, N.L., es una sociedad mercantil debidamente constituida bajo las Leyes que rigen en México y especialmente de la Ley General de Sociedades Mercantiles vigente en el País, es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección conforme a los artículo 8, 15, 16, 17, 33 y 36 de la Ley Federal de Protección de Datos Personales.
            </div>
            <div className='mt-4 indent-8'>
                Su información personal será utilizada para el proceso de reclutamiento y selección que lleva a cabo esta compañía, y con fines estrictamente en el proceso mismo requerido.
            </div>
            <div className='mt-4 indent-8'>
                Así mismo, Usted, deberá informar y realizar en dado caso, tan pronto se dé o presente alguna circunstancia o hecho que así lo amerite, la actualización de sus datos personales para que se proceda a realizar el registro correspondiente.
            </div>
            <div className='mt-4 indent-8'>
                Para las finalidades antes descritas en este Aviso de Privacidad, podemos recabar y recibir sus datos personales por cualquier medio electrónico, óptico, sonoro, visual, o a través de cualquier otra tecnología, ya sea: (i) directamente, cuando usted los proporciona en su domicilio, así como en las oficinas de VISIÓN TEC, S.A. DE C.V., o algún miembro de esta los solicite por cualquier medio ya sea por teléfono, por mensaje electrónico o mensajes de cualquier tipo; (ii) a través de nuestra página de Internet, o (iii) a través de otras fuentes permitidas por la ley, tales como los directorios telefónicos o laborales. Los datos personales que obtenemos por estos medios pueden ser en forma enunciativa pero no limitativa. Usted tiene derecho de acceder, rectificar y cancelar sus datos personales, así como de oponerse al tratamiento de los mismos o revocar el consentimiento que para tal fin nos haya otorgado, a través de los procedimientos que hemos implementado. Para conocer dichos procedimientos. Los requisitos y plazos, se puede poner en contacto con nuestro departamento de Área de Privacidad, al teléfono 8181294348 avisoprivacidad@visiontec.com.mx o visitar nuestra página de internet www.visiontec.com.mx, con horario de recepción de 10:00 diez horas a las 13:00 trece horas y de 16:00 dieciséis a 18:00 dieciocho horas.
            </div>
            <div className='mt-4 indent-8'>
                Igualmente se le hace saber que, si lo desea es su derecho oponerse respecto al tratamiento de sus datos, para el objeto de limitar su uso o divulgación de los mismos, para lo cual deberá realizar su solicitud de negativa vía correo electrónico a la dirección de avisoprivacidad@visiontec.com.mx, esto para su debido conocimiento y registro correspondiente.
            </div>
            <div className='mt-4 indent-8'>
                Asimismo, le informamos que sus datos personales pueden ser transferidos y tratados dentro y fuera del país, por terceras personas distintas a esta empresa. En ese sentido. Nos comprometemos a no transferir su información personal a terceros sin su consentimiento, salvo las excepciones previstas en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, así como a realizar esta transferencia en los términos que fija esa ley.
            </div>
            <div className='mt-4 indent-8'>
                Si usted no manifiesta su oposición para que sus datos personales sean transferidos, se entenderá que ha otorgado su consentimiento para ello. Si usted desea dejar de recibir mensajes promocionales de nuestra parte puede solicitarlo a VISIÓN TEC, S.A. DE.C.V.
            </div>
        </div>

        <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900">Medios y procedimientos para ejercer los derechos ARCO</h2>
          <div className='mt-6 text-justify'>
            <div className='indent-8'>
                En todo momento, usted podrá revocar el consentimiento que nos ha otorgado para el tratamiento de sus datos personales, a fin de que dejemos de hacer uso de los mismos. Para ello es necesaria su petición poniéndose en contacto con VISIÓN TEC, S.A. DE C.V. a través del correo electrónico avisoprivacidad@visiontec.com.mx y se le estará contactando a través del correo electrónico con el cual haya enviado su solicitud, en un plazo máximo de 15 días hábiles para atender su petición y así le informaremos el resultado de la misma.
            </div>
            <div className='mt-4 indent-8'>
                Cualquier modificación de este aviso de privacidad podrá consultarla en www.visióntec.com.mx es responsabilidad de las personas que nos hagan llegar información estar al pendiente de la misma para informarse acerca de los cambios.
            </div>
            <div className='mt-4 indent-8'>
                El envío de los datos personales por cualquier persona utilizando cualquier medio electrónico, óptico, sonoro, visual, o a través de cualquier otra tecnología, implica la aceptación y autorización de dicha persona a la forma en que VISIÓN TEC trata dichos datos personales de conformidad con el presente Aviso de Privacidad.
            </div>
            <div className='mt-4 indent-8 text-gray-400'>
                Fecha de última actualización 17 de Octubre de 2014.
            </div>
        </div>

        </div>
      </div>
    </div>
  )
}
