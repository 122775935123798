import { CheckCircleIcon } from '@heroicons/react/24/solid'
import controlImg from '../Media/audit.jpg'
import Foto_2 from '../Media/servicios2.jpg'
import Foto_3 from '../Media/servicios3.jpg'
import softwareImg from '../Media/software.jpg'

export const data = {
    control: {
        info:{
            name: 'Control de activos fijos',
            subtitle:'Mantén el control, asegura el éxito',
            description:'Nos encargamos del seguimiento detallado de tus bienes a lo largo de su vida útil, garantizando que tu empresa conozca la ubicación y el estado de tus activos, protegiéndolos contra pérdidas o daños, cumpliendo con las regulaciones contables y fiscales, y tomando decisiones informadas sobre la gestión de los activos fijos, como renovar, vender, reparar o reemplazar.',
            img: controlImg
            // img: 'https://images.prismic.io/simpliroute/c6eba6c5-95d6-4c2b-a21f-0f6b3ad9ca38_rastreo+de+paqueteria.jpg?auto=compress,format'
        },
        bullet: [
            { 
                icon: CheckCircleIcon, name:'Administración de Activos', description: 'Monitoreamos todos tus bienes muebles e inmuebles.'
            },{
                icon: CheckCircleIcon, name:'Trazabilidad', description: 'Rastreamos todos los procesos y vida útil de cada bien.'
            },{
                icon: CheckCircleIcon, name:'Tomas Físicas Frecuentes', description: 'Nos permite prevenir y corregir a tiempo.'
            },{
                icon: CheckCircleIcon, name:'Agilización de Procesos', description: 'En RH, por ejemplo, al dar entrada o salida al personal.'
            },{
                icon: CheckCircleIcon, name:'Promover el uso adecuado', description: 'De todos tus bienes, y cómo manejarlos.'
            },{
                icon: CheckCircleIcon, name:'Inhibir el robo', description: 'O extracción de los bienes de las áreas asignadas.'
            }
        ]
    }, 
    audit: {
        info: {
            name: 'Auditoría de inventarios',
            subtitle: 'La clave de la eficiencia',
            description:["Revisamos y verificamos de manera sistemática los registros, procesos y procedimientos relacionados con el inventario de tu empresa.", <div>Nos aseguramos de que los datos contables y físicos del inventario coincidan y que el inventario esté debidamente valuado, registrado y controlado de acuerdo con las prácticas contables y las regulaciones aplicables.</div>],
            img: 'https://d1ih8jugeo2m5m.cloudfront.net/2022/11/como-rastrear-un-paquete-1200x685.jpg'
        },
        bullet:[
            {
                icon: CheckCircleIcon, name:'Conteos Físicos', description: 'Para compararlos contra los inventarios lógicos.'
            },{
                icon: CheckCircleIcon, name:'Validación de existencias', description: 'Por medio de ubicación.'
            },{
                icon: CheckCircleIcon, name:'Disminución de errores', description: 'Manuales o sistemáticos.'
            },{
                icon: CheckCircleIcon, name:'Conteos Cíclicos', description: 'Reduciendo los tiempos de cada toma.'
            }
        ]

    },
    credencial: {
        info: {
            name: 'Credencialización',
            subtitle: 'Seguridad, simplicidad, credibilidad', 
            description:'Desarrollamos el proceso ideal para la emisión de tus credenciales o tarjetas de identificación, con el fin de autenticar tu identidad y otorgarle acceso a ciertos privilegios o áreas dictadas por ti, garantizando la seguridad y gestión eficiente de tus recursos y servicios.',
            img: 'https://www.specialistid.com/cdn/shop/articles/Screenshot_2021-04-16_111756.png?v=1618586484'
        },
        bullet:[
            {
                icon: CheckCircleIcon, name:'Identidad Visual', description: 'Para los miembros de la empresa.'
            },{
                icon: CheckCircleIcon, name:'Mecanismos de seguridad', description: 'Monitoreo de los accesos a los inmuebles de la empresa.'
            },{
                icon: CheckCircleIcon, name:'Sentido de Pertenencia', description: 'Para los empleados.'
            },{
                icon: CheckCircleIcon, name:'Identificación', description: 'Permitiendo a los empleados presentarse como miembros.'
            },{
                icon: CheckCircleIcon, name:'Información fija y variable', description: 'Agregada a las credenciales con calidad fotográfica.'
            },{
                icon: CheckCircleIcon, name:'Reconocimiento de participantes', description: 'Para eventos específicos.'
            }
        ]

    },
    poliza: {
        info: {
            name: 'Pólizas de Servicio',
            subtitle: 'Lorem ipsum dolor sit, consectetur adipiscing elit.',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam a aliquam ex. Curabitur egestas erat est, sit amet mollis felis aliquet at. Maecenas sollicitudin imperdiet nunc eu molestie. Suspendisse massa erat, tempus viverra consequat ac, sagittis et magna. Sed euismod tellus enim, sit amet venenatis nulla facilisis fermentum.',
            img: 'https://segurosatlantida.com/wp-content/uploads/2018/05/renovar.jpg'
        },
        bullet:[
            {
                icon: CheckCircleIcon, name:'Conteos Físicos', description: 'Para compararlos contra los inventarios lógicos.'
            },{
                icon: CheckCircleIcon, name:'Validación de existencias', description: 'Por medio de ubicación.'
            },{
                icon: CheckCircleIcon, name:'Disminución de errores', description: 'Manuales o sistemáticos.'
            },{
                icon: CheckCircleIcon, name:'Conteos Cíclicos', description: 'Reduciendo los tiempos de cada toma.'
            }
        ]

    },
    software: {
        info:{   
            name: 'Software',
            subtitle: 'Lorem ipsum dolor sit, consectetur adipiscing elit.',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam a aliquam ex. Curabitur egestas erat est, sit amet mollis felis aliquet at. Maecenas sollicitudin imperdiet nunc eu molestie. Suspendisse massa erat, tempus viverra consequat ac, sagittis et magna. Sed euismod tellus enim, sit amet venenatis nulla facilisis fermentum.',
            img: softwareImg
        },
        bullet:[
            {
                icon: CheckCircleIcon, name:'Conteos Físicos', description: 'Para compararlos contra los inventarios lógicos.'
            },{
                icon: CheckCircleIcon, name:'Validación de existencias', description: 'Por medio de ubicación.'
            },{
                icon: CheckCircleIcon, name:'Disminución de errores', description: 'Manuales o sistemáticos.'
            },{
                icon: CheckCircleIcon, name:'Conteos Cíclicos', description: 'Reduciendo los tiempos de cada toma.'
            }
        ]
    },
}