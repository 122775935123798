import React, { useEffect, useState } from 'react'

function Section({id, name, initialState, setClicked, sectionRef, change}){
  return (

    <div id={id} className={`${change == true ? 'text-cyan-400' : 'transition duration-100 hover:text-gray-300 cursor-pointer h-auto w-auto'}`}
    onClick={()=>{
      switch(id){
        case "almac":
          setClicked(({...initialState, almac: true}))
          sectionRef.current[0]?.scrollIntoView({ behavior: 'smooth' });
          break;
        case "manu":
          setClicked(({...initialState, manu: true}))
          sectionRef.current[1]?.scrollIntoView({ behavior: 'smooth' });
          break;
        case "retail":
          setClicked(({...initialState, retail: true}))
          sectionRef.current[2]?.scrollIntoView({ behavior: 'smooth' });
          break;
        case "transp":
          setClicked(({...initialState, transp: true}))
          sectionRef.current[3]?.scrollIntoView({ behavior: 'smooth' });
          break;
        case "hosp":
          setClicked(({...initialState, hosp: true}))
          sectionRef.current[4]?.scrollIntoView({ behavior: 'smooth' });
          break;
   
      }
    }}
    >
      {name}
    </div>
  )
}

export default function NavbarSol({handleRef}) {

  const initialState = {
    almac: false,
    manu: false,
    retail: false,
    transp: false,
    hosp: false
  }
  const [clicked, setClicked] = useState(initialState)

  useEffect(()=>{
    setClicked(({...initialState, almac: true}))
  }, [])

  const [change, setChange] = useState(initialState)

  const changeSection = () => {
    if(445 < window.scrollY && window.scrollY < 1720){
      setChange({...initialState, almac: true})
    } else if (1721 < window.scrollY && window.scrollY < 2545){
      setChange({...initialState, manu: true})
    } else if (2546 < window.scrollY && window.scrollY < 3370 ){
      setChange({...initialState, retail: true})
    } else if (3371 < window.scrollY && window.scrollY < 4195){
      setChange({...initialState, transp: true})
    } else {
      setChange({...initialState, hosp: true})
    }

  }

  window.addEventListener('scroll', changeSection)
  
  return (
    <div className='relative'>

      {/* <div className='absolute inset-0 bg-amber-50 -my-5 -mr-5 -ml-20 blur-2xl'>
      </div> */}

        <div className='relative ml-7 pt-5 pb-5 pl-5 font-semibold text-gray-400 w-32'>

          <div className={`${
            change.almac == true ? 'translate-y-0' : 
            change.manu == true ? 'translate-y-16' : 
            change.retail == true ? 'translate-y-32' : 
            change.transp == true ? 'translate-y-[12.75rem] scale-y-[1.45]' : 
            change.hosp == true ? 'translate-y-[17.5rem]' :'translate-y-0'} transition duration-200 ease-in-out absolute top-0 left-0 w-1 h-16 bg-cyan-400 z-10`}
          ></div>
          
          <div className='absolute top-0 left-0 w-1 h-full bg-gray-300'></div>
  
          <div className='grid grid-rows-8 space-y-10'>
  
            <Section id={'almac'} name={'almacenes'} initialState={initialState} setClicked={setClicked} sectionRef={handleRef} change={change.almac}/>
  
            <Section id={'manu'} name={'manufactura'} initialState={initialState} setClicked={setClicked} sectionRef={handleRef} change={change.manu}/>

            <Section id={'retail'} name={'retail'} initialState={initialState} setClicked={setClicked} sectionRef={handleRef} change={change.retail}/>
  
            <Section id={'transp'} name={'transporte & logística'} initialState={initialState} setClicked={setClicked} sectionRef={handleRef} change={change.transp}/>
  
            <Section id={'hosp'} name={'hospitalidad'} initialState={initialState} setClicked={setClicked} sectionRef={handleRef} change={change.hosp}/>
  
          </div>
        </div>
    </div>
  )

  
}
