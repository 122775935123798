export const storyData = [
    {
        id: 1,
        value: 'case1',
        title: 'La Belleza en Logística',
        description:
          'Estandarizando la Eficiencia: Innovación con Códigos de Barras en Manufactura de Higiene Personal.',
        imageUrl:
          'https://images.unsplash.com/photo-1631730486572-226d1f595b68?q=80&w=1975&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        date: '05 Mar, 2024',
        datetime: '2020-03-16',
        category: ['Belleza', 'Limpieza'],
        author: {
          name: 'Michael Foster',
          role: 'Co-Founder / CTO',
          imageUrl:
            'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
    },
    {
        id: 2,
        value: 'case2',
        title: 'Revolución de la Gestión Pesquera',
        description:
          'Una colaboración transformadora: Implementación de tecnología RFID para el control preciso del almacenamiento de productos marinos.',
        imageUrl:
        'https://www.grupopinsa.mx/assets/images/template/pesca-azteca.png',
        date: '05 Mar, 2024',
        datetime: '2020-03-16',
        category: ['Pesca'],
        author: {
          name: 'Michael Foster',
          role: 'Co-Founder / CTO',
          imageUrl:
            'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      {
        id: 3,
        value: 'case3',
        title: 'Empaques con Impacto',
        description:
          'La implementación de la tecnología RFID en los rollos de productos para empaque ha beneficiado la gestión logística.',
        imageUrl:
          'https://images.unsplash.com/photo-1600009723489-027195d6b3d3?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        date: '05 Mar, 2024',
        datetime: '2020-03-16',
        category: ['Empaque'],
        author: {
          name: 'Michael Foster',
          role: 'Co-Founder / CTO',
          imageUrl:
            'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      {
        id: 4,
        value: 'case4',
        title: 'Administración Eficiente',
        description:
          'Innovación en la operación para establecer un control integral del inventario.',
        imageUrl:
          'https://images.unsplash.com/photo-1426927308491-6380b6a9936f?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        date: '05 Mar, 2024',
        datetime: '2020-03-16',
        category: ['Marketing'],
        author: {
          name: 'Michael Foster',
          role: 'Co-Founder / CTO',
          imageUrl:
            'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
]