import React from 'react'

export default function BtnSolutionNav({icon, name}) {
  return (
    // <div className='h-20 w-full hover:cursor-pointer grid grid-cols-1 justify-items-center
    // md:flex md:items-end md:justify-start
    // [&_#icon]:transition [&_#icon]:duration-300 [&_#icon]:ease-in-out [&_#icon]:hover:opacity-60 
    // [&_#name]:transition [&_#name]:duration-300 [&_#name]:ease-in-out [&_#name]:hover:text-zinc-500'>
    <div className='h-20 w-full hover:cursor-pointer grid grid-cols-1 justify-items-center
    [&_#icon]:transition [&_#icon]:duration-300 [&_#icon]:ease-in-out [&_#icon]:hover:opacity-60 
    [&_#name]:transition [&_#name]:duration-300 [&_#name]:ease-in-out [&_#name]:hover:text-zinc-500'>
        {/* <div className='flex-none bg-cover w-[4.5rem] h-[4.5rem]
        md:w-20 md:h-20 md:mr-5'> */}
        <div className='flex-none bg-cover w-16 h-16'>
            <img
            src={icon}
            alt='Icon'
            id='icon'
            className='w-full h-full opacity-20'/>
        </div>
        {/* <div id='name' className='text-base font-bold text-zinc-300 leading-[1.15rem] text-center mt-2
        md:text-[29px] md:leading-9 md:mt-[0px] md:text-left'>{name}</div> */}
        <div id='name' className='text-lg font-bold text-zinc-300 leading-[1.4rem] text-center mt-2
        sm:text-base sm:leading-5
        lg:text-[22px] lg:leading-6 lg:mt-3'>{name}</div>
    </div>
  )
}
