import React, { useEffect } from 'react'
import { data } from '../../Data/infoSuccessStoryPage'
import { useParams } from 'react-router';
import { storyData } from '../../Data/InfoStories'
import { Link } from 'react-router-dom';


export default function SuccessStoryPage() {


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    const {type} = useParams();
    const {value, heroSection, information} =  data[type];


  return (
    <div className='bg-white'>

        {/* HERO SECTION */}
      <main className="relative isolate pt-20">
          <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
          </svg>
          <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-sky-300 to-red-300 opacity-40"
              style={{
                clipPath:
                  'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
              }}
            />
          </div>
          <div className="overflow-hidden">
          <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
            <div className="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-48 xl:col-span-6">
              <div className="mx-auto max-w-2xl lg:mx-0">
                <div className="hidden sm:mb-8 sm:flex">
                    <div className="relative rounded-full px-4 py-2 text-md leading-6 text-gray-600 underline underline-offset-[10px]">
                    Casos de Éxito
                    </div>
                </div>
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  {heroSection.title}
                </h1>
                <p className="relative mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                  {heroSection.text}
                </p>
                {/* <div className="mt-10 flex items-center gap-x-6">
                  <a
                    href="#"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Get started
                  </a>
                  <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                    Learn more <span aria-hidden="true">→</span>
                  </a>
                </div> */}
              </div>
            </div>
            <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-[60%] xl:mr-0">
              <img
                className={`aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full lg:rounded-3xl ${value == 'case1' && 'brightness-110'}`}
                src={heroSection.img}
                alt=""
              />
              {value == 'case1' &&
              <div className="absolute inset-0 bg-sky-200 mix-blend-color lg:rounded-3xl " />
              }
            </div>
          </div>
          </div>
      </main>



    {/* INFORMATION */}
        <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-4">
                <div className="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
                <img
                    className="absolute inset-0 h-full w-full object-cover brightness-125 saturate-0"
                    src={information.statement.bgImg}
                    alt=""
                />
                <div className="absolute inset-0 bg-gray-900 mix-blend-multiply" />
                <div
                    className="absolute left-1/2 top-1/2 -ml-16 -translate-x-1/2 -translate-y-1/2 transform-gpu blur-3xl"
                    aria-hidden="true"
                >
                    <div
                    className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-40"
                    style={{
                        clipPath:
                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                    />
                </div>
                <figure className="relative isolate">
                    <svg
                    viewBox="0 0 162 128"
                    fill="none"
                    aria-hidden="true"
                    className="absolute -left-2 -top-4 -z-10 h-32 stroke-white/20"
                    >
                    <path
                        id="0ef284b8-28c2-426e-9442-8655d393522e"
                        d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z"
                    />
                    <use href="#0ef284b8-28c2-426e-9442-8655d393522e" x={86} />
                    </svg>
                    {/* <img src={information.statement.logo} alt="" className="h-16 w-auto" /> */}
                    <blockquote className="mt-6 text-xl font-semibold leading-8 text-white">
                    <p>
                    {information.statement.text}
                    </p>
                    </blockquote>
                    <figcaption className="mt-6 text-sm leading-6 text-gray-300">
                    <strong className="font-semibold text-white">{information.statement.author},</strong> {information.statement.dept}
                    </figcaption>
                </figure>
                </div>
            </div>
            <div>
                <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                {/* <p className="text-base font-semibold leading-7 text-indigo-600">{information.title}</p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {information.subtitle}
                </h1> */}
                <div className="max-w-xl">
                    <p className="mt-6">
                    {information.paragraph1}
                    </p>
                    <p className="mt-8">
                    {information.paragraph2}
                    </p>
                    <p className="mt-8">
                    {information.paragraph3}
                    </p>
                </div>
                </div>
                {/* <dl className="mt-10 grid grid-cols-2 gap-8 border-t border-gray-900/10 pt-10 sm:grid-cols-4">
                {information.statistics.map((stat, statIdx) => (
                    <div key={statIdx}>
                    <dt className="text-sm font-semibold leading-6 text-gray-600">{stat.label}</dt>
                    <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-gray-900">{stat.value}</dd>
                    </div>
                ))}
                </dl> */}
                <div className="mt-10 flex">
                <Link to={information.url} className="text-base font-semibold leading-7 text-indigo-600">
                    Conoce más sobre la compañía <span aria-hidden="true">&rarr;</span>
                </Link>
                </div>
            </div>
            </div>
        </div>
        </div>


        {/* MORE STORIES */}
        <div className="bg-white pb-24 pt-12 sm:pb-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Más casos de éxito</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
                Conoce las historias de nuestros colaboradores.
            </p>
            </div>
            <div className="mx-auto mt-12 grid max-w-2xl auto-rows-fr grid-cols-1 gap-7 sm:mt-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
            {storyData.filter((post)=> {
                if(post.value == value){
                    return false //skip
                }
                return true
            }).map((post) => (
                <Link to={`/story/${post.value}`} onClick={()=>{
                    window.scrollTo(0, 0)
                }}>
                <article
                key={post.id}
                className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-32 lg:pt-52"
                >
                <img src={post.imageUrl} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />

                <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                    <time dateTime={post.datetime} className="mr-8">
                    {post.date}
                    </time>
                    <div className="-ml-4 flex items-center gap-x-4">
                    <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                        <circle cx={1} cy={1} r={1} />
                    </svg>
                    {/* <div className="flex gap-x-2.5">
                        <img src={post.author.imageUrl} alt="" className="h-6 w-6 flex-none rounded-full bg-white/10" />
                        {post.author.name}
                    </div> */}
                    </div>
                </div>
                <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                    <a href={post.href}>
                    <span className="absolute inset-0" />
                    {post.title}
                    </a>
                </h3>
                </article>
                </Link>
            ))}
            </div>
        </div>
        </div>
    </div>
  )
}
