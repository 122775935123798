import { CheckCircleIcon } from '@heroicons/react/24/solid'

export const elementData = [
    {
        title: 'Innovación',
        info: `Buscamos nuevas soluciones tecnológicas para satisfacer tus necesidades específicas.`,
        URLimage: 'https://cdn-icons-png.flaticon.com/128/171/171323.png'
    },
    {
        title: 'Calidad',
        info: `Comprometidos con la excelencia en el soporte de los equipos, así como en el desarrollo digital.`,
        URLimage: 'https://cdn-icons-png.flaticon.com/128/8189/8189053.png'
    },
    {
        title: 'Adaptabilidad',
        info: `Dispuestos a ajustarnos y evolucionar en un entorno tecnológico cambiante.`,
        URLimage: 'https://cdn-icons-png.flaticon.com/128/4598/4598108.png'
    },
    {
        title: 'Responsabilidad',
        info: `Cumplimos las tareas en tiempo y forma, actuando con ética y honestidad.`,
        URLimage: 'https://cdn-icons-png.flaticon.com/128/4411/4411370.png'
    }
]

export const industryData = [
  {
    name: 'Almacenes',
    img: 'https://images.unsplash.com/photo-1616401784845-180882ba9ba8?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    link: '/vertical/warehouses'
  },
  {
    name: 'Manufactura',
    img: 'https://images.unsplash.com/photo-1547895749-888a559fc2a7?auto=format&fit=crop&q=80&w=1964&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    link: '/vertical/manufacture'
  },
  {
    name: 'Retail',
    img: 'https://images.unsplash.com/photo-1570857502809-08184874388e?auto=format&fit=crop&q=80&w=2078&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    link: '/vertical/retail'
  },
  {
    name: 'Hospitalidad',
    img: 'https://images.unsplash.com/photo-1544986581-efac024faf62?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    link: '/vertical/hospitality'
  },
  {
    name: 'Transporte y Logística',
    img: 'https://plus.unsplash.com/premium_photo-1661932015882-c35eee885897?auto=format&fit=crop&q=80&w=2206&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    link: '/vertical/transport'
  },
]



export const solutionData = [
  { 
    tab: 'Nivel 1',
    title:'Nivel 1: Solución Básica de Control de Inventarios',
    subtitle: 'Control de inventarios con Código de barras y Celular',
    description: 'Esta solución ofrece una manera sencilla y accesible de gestionar inventarios utilizando la cámara de un teléfono móvil como lector de códigos de barras.',
    img: 'https://s15543.pcdn.co/wp-content/uploads/2021/03/21_logistics_xcoverpro_barcode-scanning-900x450.jpg',
    imgStyle: 'object-fill lg:w-[45rem]',
    // imgStyle: 'object-fill lg:w-[64rem]',
    features: [
      {
        icon: CheckCircleIcon,
        name: 'Accesibilidad.',
        description: 'Utiliza dispositivos móviles comunes, lo que hace que sea fácil de implementar y usar.',
      },
      {
        icon: CheckCircleIcon,
        name: 'Costo efectivo.',
        description: 'Requiere una inversion mínima en hardware adicional, aprovechando los recursos existentes.',
      },
      {
        icon: CheckCircleIcon,
        name: 'Facilidad de uso.',
        description: 'No se necesitan conocimientos técnicos avanzados para comenzar a gestionar inventarios de manera eficiente.',
      },
    ]
  },
  { 
    tab: 'Nivel 2' , 
    title:'Nivel 2: Solución Avanzada de Control de Inventarios',
    subtitle: 'Control de inventarios con Software especializado y Hardware robusto',
    description: 'Este nivel ofrece un software especializado junto con hardware robusto diseñado para el control de inventarios en entornos comerciales o industriales.',
    img: 'https://www.barcoding.com/hubfs/BARSCANNERBLOGFinal.png',
    imgStyle: 'object-fill lg:w-[42rem] object-right',
    features: [
      {
        icon: CheckCircleIcon,
        name: 'Escalabilidad.',
        description: 'Capacidad para gestionar grandes volúmenes de inventario y adaptarse al crecimiento del negocio.',
      },
      {
        icon: CheckCircleIcon,
        name: 'Fiabilidad.',
        description: 'Hardware diseñado para resistir condiciones adversas y proporcionar un rendimiento consistente.',
      },
      {
        icon: CheckCircleIcon,
        name: 'Funcionalidad avanzada.',
        description: 'Ofrece características adicionales como análisis de datos en tiempo real y seguimiento de activos.',
      },
    ]
  },
  { 
    tab: 'Nivel 3' , 
    title:'Nivel 3: Solución de Control de Inventarios con RFID',
    subtitle: 'Control de inventarios con Tecnología RFID',
    description: 'Esta solución utiliza tecnología de Identificación por Radiofrecuencia (RFID) para un seguimiento preciso y eficiente de inventarios.',
    img: 'https://go-e.com/fileadmin/_processed_/1/a/csm_rfid-system-transponder-reader_8e5a84b6ec.jpg',
    imgStyle: 'object-cover lg:w-[61rem] object-right',
    features: [
      {
        icon: CheckCircleIcon,
        name: 'Automatización.',
        description: 'Permite el rastreo de múltiples productos simultáneamente sin necesidad de escanear individualmente.',
      },
      {
        icon: CheckCircleIcon,
        name: 'Exactitud.',
        description: 'Proporciona una precisión superior en comparación con los métodos tradicionales de gestión de inventarios.',
      },
      {
        icon: CheckCircleIcon,
        name: 'Velocidad.',
        description: 'Agiliza los procesos de inventario al reducir el tiempo necesario para realizar conteos físicos.',
      },
    ]
  },
  { 
    tab: 'Nivel 4' , 
    title:'Nivel 4: Solución de Control de Inv. con IA y Visión',
    subtitle: 'Control de inventarios con Inteligencia Artificial y Visión por computadora',
    description: 'Este nivel emplea inteligencia artificial y tecnología de visión por computadora para una gestión de inventarios aún más automatizada y precisa.',
    img: 'https://www.brightpearl.com/wp-content/uploads/2023/07/warehouse_managers_guide_to_tackling_inventory_shortages_head_on_effective_inventory_and_order_management_in_retail-scaled.jpeg',
    imgStyle: 'object-cover lg:w-[53rem] object-right',
    features: [
      {
        icon: CheckCircleIcon,
        name: 'Predicción de demanda.',
        description: 'Utiliza algoritmos de IA para prever patrones de demanda y optimizar los niveles de inventario.',
      },
      {
        icon: CheckCircleIcon,
        name: 'Detección de anomalías.',
        description: 'Identifica de manera proactiva problemas como pérdidas, daños o productos desfasados.',
      },
      {
        icon: CheckCircleIcon,
        name: 'Eficiencia operativa.',
        description: 'Optimiza los procesos de inventario al reducir la necesidad de intervención humana y mejorar la toma de decisiones.',
      },
    ]
  },
]
