import React, { useEffect } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

export default function HeroSection({handleClick, title, subtitle, text, img1, img2, img3}) {
  
  return (


    <div className="bg-gray-900">
      <div className="relative isolate overflow-hidden py-[5.2rem]">
        {/* <img
          src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2830&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover"
        /> */}

        <div className='absolute inset-0 -z-10 h-full w-full brightness-[.3] '>
            <Carousel 
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            stopOnHover={false}
            swipeable={false}
            animationHandler="fade"
            autoPlay={true}
            infiniteLoop={true}
            dynamicHeight={true}
            >
                <div>
                    <img className='h-full w-full object-cover' src={img1} />
                </div>
                <div>
                    <img className='h-full w-full object-cover' src={img2} />
                </div>
                <div>
                    <img className='h-full w-full object-cover' src={img3} />
                </div>
            </Carousel>
        </div>

        
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full px-4 py-2 text-md leading-6 text-white ring-1 ring-white/10">
              {title}
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
              {subtitle}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              {text}
            </p>
            {/* <div className="mt-10 flex items-center justify-center gap-x-6">
              <p
                onClick={handleClick}
                className="rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
              >
                Conocer más →
              </p>
            </div> */}
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>

 
    // <div className='h-auto'>
    //     <div className='absolute z-10 w-full'>
    //         <div className='max-w-md mx-auto px-8
    //         sm:max-w-lg sm:px-0 
    //         lg:max-w-2xl lg:mx-0 lg:ml-[15%]'>
    //             <div className='mt-44 tracking-wider font-bold text-white text-xl 
    //             sm:text-2xl
    //             lg:text-3xl lg:tracking-wide'>
    //                 {title}
    //             </div>
    //             <div className='leading-[3rem] pt-2 font-bold text-white text-[43px] tracking-wide
    //             sm:text-[57px] sm:leading-[4rem]
    //             lg:text-7xl lg:leading-[1.1] lg:tracking-normal'>
    //                 {subtitle}
    //             </div>
    //             <div className='tracking-wide leading-7 pt-14 text-white text-base 
    //             sm:leading-[2rem] sm:text-xl
    //             lg:leading-[2.5rem] lg:text-2xl lg:pt-20'>
    //             {text}
    //             </div>
    //         </div>
    //     </div>


    //     <div className='w-full h-auto'>
    //         <Carousel 
    //         showArrows={false}
    //         showStatus={false}
    //         showIndicators={false}
    //         showThumbs={false}
    //         stopOnHover={false}
    //         swipeable={false}
    //         animationHandler="fade"
    //         autoPlay={true}
    //         infiniteLoop={true}
    //         >
    //             <div>
    //                 <img src={img1} />
    //             </div>
    //             <div>
    //                 <img src={img2} />
    //             </div>
    //             <div>
    //                 <img src={img3} />
    //             </div>
    //         </Carousel>
    //     </div>
    // </div>
  
  )
}
