import React from 'react'

export default function ServiceItem({icon, name, description}) {

  return (
    <div className='grid grid-cols-1'>
        <div className='flex flex-col items-center h-auto
        sm:flex-row'>
            <div className='flex-none bg-cover w-12 h-12 mb-2
            sm:w-12 sm:h-12 sm:mr-3 sm:mb-0
            lg:mr-5'>
                {/* <img
                src={icon}
                alt='Icon'
                id='icon'
                className='w-full h-full'/> */}
                {icon}
            </div>
            <div className='text-sm text-center text-zinc-600 font-semibold
            sm:text-base sm:text-left
            lg:text-xl lg:font-normal'>{name}</div>
        </div>
        <div className='mt-2 text-zinc-400 text-sm
        sm:mt-4
        lg:text-base lg:leading-7'>
            {description}
        </div>
    </div>
  )
}
