import { Link, useNavigate, useLocation } from 'react-router-dom'
import LogoAzul from '../../Media/LogoAzul.png'
import LogoNegro from '../../Media/LogoNegro.png'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import NavbarDropdown from './NavbarDropdown'
import NavbarSmResp from './NavbarSmResp'
import { dropdownData } from '../../Data/infoNavbarDropdown'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { useState } from 'react'


export default function Navbar() {

  const navigate = useNavigate()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const {pathname} = useLocation()

  return (
    <header>
    <nav className="bg-white fixed z-50 w-full">
      <div className='
      pb-4 pt-4 shadow-md
      relative flex inset-x-0 top-0'>
        <div className='
      flex items-center gap-6 md:gap-10 lg:gap-16 pl-6 md:pl-10 
      lg:pl-16'>
        <Link to={"/"} >
        <img
        src={LogoAzul}
        alt='Logo'
        placeholder='blur'
        className='w-28 
        md:w-36 lg:w-44'
        />
        </Link>

        {/* <Link to={"/solutions"}>
        <p className='text-gray-600 text-sm md:text-base lg:text-lg hidden sm:inline'>Soluciones</p>
        </Link> */}

        <div className='hidden sm:inline'>
        <NavbarDropdown type={1} name={"Industrias"} link={"/solutions"} data={dropdownData.Industries}/>
        </div>

        {/* <Link to={"/services"}>
        <p className='text-gray-600 text-sm md:text-base lg:text-lg hidden sm:inline' >Servicios</p>
        </Link> */}

        <div className='hidden sm:inline'>
        <NavbarDropdown type={2} name={"Servicios"} link={"/services"} data={dropdownData.Services}/>
        </div>

        {/* <Link to={"/swrya"}>
        <p className={`text-sm md:text-base lg:text-lg hidden sm:inline ${pathname === "/swrya" ? "text-blue-900" : "text-gray-600"}`} >SWRYA</p>
        </Link> */}
        
        {/* <p className='text-gray-600 text-sm md:text-base lg:text-lg hidden sm:inline' >eCommerce</p> */}
        </div>

        <div className='absolute inset-y-0 right-0 flex items-center pr-6 gap-6 
        md:gap-10 lg:pr-16 lg:gap-16' >
          <MagnifyingGlassIcon className="h-4 w-4 md:h-5 md:w-5 lg:h-6 lg:w-6 text-gray-600 hidden sm:inline" />
          {/* <Bars3Icon className="h-5 w-5 lg:h-6 lg:w-6 text-gray-600 inline sm:hidden" /> */}
          <div className="flex sm:hidden">
          <button
            type="button"
            className="inline-flex items-center text-gray-600"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
          <p className='text-gray-600 text-sm md:text-base lg:text-lg hidden sm:inline' >Soporte</p>
        </div>
        </div>
    </nav>

    <NavbarSmResp mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />

    </header>
  )
}