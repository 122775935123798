import {
    BuildingOfficeIcon,
    ArchiveBoxIcon,
    CogIcon,
    BuildingStorefrontIcon,
    TruckIcon,
    CircleStackIcon, 
    ClipboardDocumentListIcon,
    IdentificationIcon,
    ShieldCheckIcon,
    ComputerDesktopIcon
  } from '@heroicons/react/24/outline'

export const dropdownData = {
    Industries: [
        { name: 'Almacenes', href: '/vertical/warehouses', icon: ArchiveBoxIcon },
        { name: 'Manufactura', href: '/vertical/manufacture', icon: CogIcon },
        { name: 'Retail', href: '/vertical/retail', icon: BuildingStorefrontIcon },
        { name: 'Transporte y Logística', href: '/vertical/transport', icon: TruckIcon },
        { name: 'Hospitalidad', href: '/vertical/hospitality', icon: BuildingOfficeIcon }
    ], 
    Services: [
        { name: 'Control de Activos Fijos', description: 'Get a better understanding of your traffic', href: '/services', linkRef: 'control', icon: CircleStackIcon },
        { name: 'Auditoria de inventarios', description: 'Speak directly to your customers', href: '/services', linkRef: 'audit', icon: ClipboardDocumentListIcon },
        { name: 'Credencialización', description: "Your customers' data will be safe and secure", href: '/services', linkRef: 'cred', icon: IdentificationIcon },
        { name: 'Pólizas de Servicio', description: 'Connect with third-party tools', href: '/services', linkRef: 'poliza', icon: ShieldCheckIcon },
        { name: 'Software', description: 'Build strategic funnels that will convert', href: '/services', linkRef: 'software', icon: ComputerDesktopIcon},
    ]

}