import React from 'react'
import { navigation, social } from '../../Data/infoFooter'
import LogoBlanco from '../../Media/LogoBlanco.png'
import { Link } from 'react-router-dom'

export default function Footer() {


  return (

    <footer className="bg-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8">
            <Link to={"/"} >
            <img
              className="h-14"
              src={LogoBlanco}
              alt="Company name"
            />
            </Link>
            <p className="text-sm leading-6 text-gray-300">
              Vision Tec - Soluciones a tiempo real
            </p>
            {/* <div className="flex space-x-6">
              {social.map((item) => (
                <a key={item.name} href={item.href} className="text-gray-500 hover:text-gray-400">
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div> */}
          </div>
          <div className="mt-16 grid grid-cols-2 md:grid md:grid-cols-4 gap-8 xl:col-span-2 xl:mt-0">
              {navigation.map((item) => ( 
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-white">{item.name}</h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {item.links.map((link) => (
                      <li key={link.name}>
                        {item.name == "Servicios" || link.name == 'Casos de Éxito'  ? 
                          <Link to={link.href} state={link.linkRef} className="text-sm leading-6 text-gray-300 hover:text-white">
                            {link.name}
                          </Link>
                          :
                          <Link to={link.href} className="text-sm leading-6 text-gray-300 hover:text-white">
                            {link.name}
                          </Link>
                        }
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
          </div>
        </div>
        <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-gray-400">&copy; 2020 Your Company, Inc. All rights reserved.</p>
        </div>
      </div>
    </footer>


    // <div className="bg-gray-700 pb-32 pt-20">
    //   <div className='grid grid-cols-7'>
    //     <div className='col-span-1' />
    //     <div className='col-span-1 text-white text-sm space-y-3'>
    //         <div className="text-xl font-semibold tracking-widest">
    //           VisionTec
    //         </div>
    //         <div className="">
    //           Modelo de Trabajo
    //         </div>
    //         <div className="">
    //           Soluciones
    //         </div>
    //         <div className="">
    //           Servicios
    //         </div>
    //     </div>
    //     <div className='col-span-1 text-white text-sm space-y-3'>
    //         <div className="text-xl font-semibold tracking-widest">
    //           eCommerce
    //         </div>
    //         <div className="">
    //           Tienda
    //         </div>
    //         <div className="">
    //           Más vendidos
    //         </div>
    //         <div className="">
    //           Nuevos lanzamientos
    //         </div>
    //         <div className="">
    //           Métodos de pago
    //         </div>
    //         <div className="">
    //           Envíos
    //         </div>
    //     </div>
    //     <div className='col-span-1 text-white text-sm space-y-3'>
    //         <div className="text-xl font-semibold tracking-widest">
    //           Compañía
    //         </div>
    //         <div className="">
    //           Sobre nosotros
    //         </div>
    //         <div className="">
    //           Términos y condiciones
    //         </div>
    //         <div className="">
    //           Aviso de privacidad
    //         </div>
    //     </div>
    //     <div className='col-span-1 text-white text-sm space-y-3'>
    //         <div className="text-xl font-semibold tracking-widest">
    //           Soporte
    //         </div>
    //         <div className="">
    //           FAQ
    //         </div>
    //         <div className="">
    //           Contacto
    //         </div>
    //         <div className="">
    //           Teléfonos
    //         </div>
    //     </div>
    //     </div> 
    //     <div className='grid grid-cols-7 pt-8'>
    //         <div className='col-span-1' />
    //         <div className='col-span-4 border-b' />
    //     </div>
    // </div>
  )
}
