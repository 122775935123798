import { AcademicCapIcon, HandRaisedIcon, RocketLaunchIcon, SparklesIcon, SunIcon, UserGroupIcon } from "@heroicons/react/20/solid";

//TODO: informacion que se llenara para mostrar en cada verticalPage de cada seccion

export const data = {
    warehouses: {
        banner: {
            title: 'Optimizando Espacios, Maximizando Eficiencia',
            infoSubtitle: `Buscamos mejorar la eficiencia, reducir costos, minimizar errores y aumentar la productividad en el entorno de almacenamiento, lo que a su vez contribuye 
                           a un flujo de trabajo más efectivo y a la satisfacción de los clientes.`,
            imgBackgoundBanner: 'https://images.unsplash.com/photo-1587293852726-70cdb56c2866?auto=format&fit=crop&q=80[…]3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            imgBanner: 'https://www.aldeaeducativa.ag/imagenes/T%C3%A9cnico%20Auxiliar%20en%20Log%C3%ADstica,%20Planificaci%C3%B3n,%20Gesti%C3%B3n%20y%20Control%20de%20Inventarios.jpg'
        },
        mision: {
            title: 'Mision',
            paragraph1: 'We’re a passionate group of people working from around the world to build the future of ecommerce.',
            paragraph2: `Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt
                        amet fugiat veniam occaecat fugiat aliqua. Anim aute id magna aliqua ad ad non deserunt sunt. Qui
                        irure qui lorem cupidatat commodo.`,
            paragraph3: 'Elit sunt amet fugiat veniam occaecat fugiat aliqua. Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
            stats: [
                    { label: 'Transactions every 24 hours', value: '44 million' },
                    { label: 'Assets under holding', value: '$119 trillion' },
                    { label: 'New users annually', value: '46,000' },
                ],
            img: 'https://images.unsplash.com/photo-1627309366653-2dedc084cdf1?auto=format&fit=crop&q=80[…]3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },
        values: {
            title: 'Valores',
            subtitle: 'Los impulsores de nuestros lavores',
            infoValues: [
                {
                    name: 'Be world-class.',
                    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
                    icon: RocketLaunchIcon,
                },
                {
                    name: 'Take responsibility.',
                    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
                    icon: HandRaisedIcon,
                },
                {
                    name: 'Be supportive.',
                    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus voluptas blanditiis et.',
                    icon: UserGroupIcon,
                },
                {
                    name: 'Always learning.',
                    description: 'Iure sed ab. Aperiam optio placeat dolor facere. Officiis pariatur eveniet atque et dolor.',
                    icon: AcademicCapIcon,
                },
                {
                    name: 'Share everything you know.',
                    description: 'Laudantium tempora sint ut consectetur ratione. Ut illum ut rem numquam fuga delectus.',
                    icon: SparklesIcon,
                },
                {
                    name: 'Enjoy downtime.',
                    description: 'Culpa dolorem voluptatem velit autem rerum qui et corrupti. Quibusdam quo placeat.',
                    icon: SunIcon,
                },
            ]
        },
        solutions: {
            title: 'Soluciones',
            subtitle: 'Este es el subitulo de las soluciones',
            infoCards: [
                {title: 'title 1', img:'https://www.kantar.com/latin-america/-/media/project/kantar/latin-america/inspiracion/ofrenda-site_ok.jpg?h=601&w=900&hash=7DBEC2A8233BE4642B40D8C72C42753D'},
                {title: 'title 2', img:'https://c.files.bbci.co.uk/DDDA/production/_115149765_db4c2ec4-c6cf-418a-9a7f-1b43bb2ede25.jpg'},
                {title: 'title 3', img:'https://media.admagazine.com/photos/618a62eea1fa32def5822dc6/16:9/w_2560%2Cc_limit/77805.jpg'},
                {title: 'title 4', img:'https://www.kantar.com/latin-america/-/media/project/kantar/latin-america/inspiracion/ofrenda-site_ok.jpg?h=601&w=900&hash=7DBEC2A8233BE4642B40D8C72C42753D'},
                {title: 'title 5', img:'https://c.files.bbci.co.uk/DDDA/production/_115149765_db4c2ec4-c6cf-418a-9a7f-1b43bb2ede25.jpg'},
                {title: 'title 6', img:'https://media.admagazine.com/photos/618a62eea1fa32def5822dc6/16:9/w_2560%2Cc_limit/77805.jpg'},
            ]
        }
    },
    manufacture: {
        banner: {
            title: 'Software de Excelencia, Manufactura de Innovación',
            infoSubtitle: `Ofrecemos soluciones para aumentar la eficiencia, reducir costos, mejorar la calidad del producto y acelerar la producción, 
                           lo que a su vez contribuye a la competitividad de la empresa en el mercado y a la satisfacción de los clientes`,
            imgBackgoundBanner: 'https://images.unsplash.com/photo-1578604665675-9aee692f6ddc?auto=format&fit=crop&q=80[…]3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            imgBanner: 'https://images.unsplash.com/photo-1581091226033-d5c48150dbaa?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },
        mision: {
            title: 'Mision',
            paragraph1: 'We’re a passionate group of people working from around the world to build the future of ecommerce.',
            paragraph2: `Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt
                        amet fugiat veniam occaecat fugiat aliqua. Anim aute id magna aliqua ad ad non deserunt sunt. Qui
                        irure qui lorem cupidatat commodo.`,
            paragraph3: 'Elit sunt amet fugiat veniam occaecat fugiat aliqua. Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
            stats: [
                    { label: 'Transactions every 24 hours', value: '44 million' },
                    { label: 'Assets under holding', value: '$119 trillion' },
                    { label: 'New users annually', value: '46,000' },
                ],
            img: 'https://images.unsplash.com/photo-1610891015188-5369212db097?auto=format&fit=crop&q=80[…]3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },
        values: {
            title: 'Valores',
            subtitle: 'Los impulsores de nuestros lavores',
            infoValues: [
                {
                    name: 'Be world-class.',
                    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
                    icon: RocketLaunchIcon,
                },
                {
                    name: 'Take responsibility.',
                    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
                    icon: HandRaisedIcon,
                },
                {
                    name: 'Be supportive.',
                    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus voluptas blanditiis et.',
                    icon: UserGroupIcon,
                },
                {
                    name: 'Always learning.',
                    description: 'Iure sed ab. Aperiam optio placeat dolor facere. Officiis pariatur eveniet atque et dolor.',
                    icon: AcademicCapIcon,
                },
                {
                    name: 'Share everything you know.',
                    description: 'Laudantium tempora sint ut consectetur ratione. Ut illum ut rem numquam fuga delectus.',
                    icon: SparklesIcon,
                },
                {
                    name: 'Enjoy downtime.',
                    description: 'Culpa dolorem voluptatem velit autem rerum qui et corrupti. Quibusdam quo placeat.',
                    icon: SunIcon,
                },
            ]
        },
        solutions: {
            title: 'Soluciones',
            subtitle: 'Vision tec, soluciones en tiempo real',
            infoCards: [
                {title: 'title 1', img:'https://www.kantar.com/latin-america/-/media/project/kantar/latin-america/inspiracion/ofrenda-site_ok.jpg?h=601&w=900&hash=7DBEC2A8233BE4642B40D8C72C42753D'},
                {title: 'title 2', img:'https://c.files.bbci.co.uk/DDDA/production/_115149765_db4c2ec4-c6cf-418a-9a7f-1b43bb2ede25.jpg'},
                {title: 'title 3', img:'https://media.admagazine.com/photos/618a62eea1fa32def5822dc6/16:9/w_2560%2Cc_limit/77805.jpg'},
                {title: 'title 4', img:'https://www.kantar.com/latin-america/-/media/project/kantar/latin-america/inspiracion/ofrenda-site_ok.jpg?h=601&w=900&hash=7DBEC2A8233BE4642B40D8C72C42753D'},
                {title: 'title 5', img:'https://c.files.bbci.co.uk/DDDA/production/_115149765_db4c2ec4-c6cf-418a-9a7f-1b43bb2ede25.jpg'},
                {title: 'title 6', img:'https://media.admagazine.com/photos/618a62eea1fa32def5822dc6/16:9/w_2560%2Cc_limit/77805.jpg'},
            ]
        }
    },
    retail:{
        banner: {
            title: 'Elevando tu tienda al Siguiente Nivel',
            infoSubtitle: `Nuestro objetivo es aumentar la eficiencia, optimizar la experiencia del cliente, impulsar las ventas y la fidelización de los 
                           clientes, y mantenerse competitivo en un mercado minorista en constante evolución. Buscamos  adaptar tu tienda a las demandas 
                           cambiantes de los consumidores y mejorar su rentabilidad.`,
            imgBackgoundBanner: 'https://images.unsplash.com/photo-1527368746281-798b65e1ac6e?auto=format&fit=crop&q=80[…]3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            imgBanner: 'https://images.unsplash.com/photo-1556741533-6e6a62bd8b49?auto=format&fit=crop&q=80&w=[…]3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },
        mision: {
            title: 'Mision',
            paragraph1: 'We’re a passionate group of people working from around the world to build the future of ecommerce.',
            paragraph2: `Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt
                        amet fugiat veniam occaecat fugiat aliqua. Anim aute id magna aliqua ad ad non deserunt sunt. Qui
                        irure qui lorem cupidatat commodo.`,
            paragraph3: 'Elit sunt amet fugiat veniam occaecat fugiat aliqua. Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
            stats: [
                    { label: 'Transactions every 24 hours', value: '44 million' },
                    { label: 'Assets under holding', value: '$119 trillion' },
                    { label: 'New users annually', value: '46,000' },
                ],
            img: 'https://images.unsplash.com/photo-1533481405265-e9ce0c044abb?auto=format&fit=crop&q=80[…]3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },
        values: {
            title: 'Valores',
            subtitle: 'Los impulsores de nuestros lavores',
            infoValues: [
                {
                    name: 'Be world-class.',
                    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
                    icon: RocketLaunchIcon,
                },
                {
                    name: 'Take responsibility.',
                    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
                    icon: HandRaisedIcon,
                },
                {
                    name: 'Be supportive.',
                    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus voluptas blanditiis et.',
                    icon: UserGroupIcon,
                },
                {
                    name: 'Always learning.',
                    description: 'Iure sed ab. Aperiam optio placeat dolor facere. Officiis pariatur eveniet atque et dolor.',
                    icon: AcademicCapIcon,
                },
                {
                    name: 'Share everything you know.',
                    description: 'Laudantium tempora sint ut consectetur ratione. Ut illum ut rem numquam fuga delectus.',
                    icon: SparklesIcon,
                },
                {
                    name: 'Enjoy downtime.',
                    description: 'Culpa dolorem voluptatem velit autem rerum qui et corrupti. Quibusdam quo placeat.',
                    icon: SunIcon,
                },
            ]
        },
        solutions: {
            title: 'Soluciones',
            subtitle: 'Vision tec, soluciones en tiempo real',
            infoCards: [
                {title: 'title 1', img:'https://www.kantar.com/latin-america/-/media/project/kantar/latin-america/inspiracion/ofrenda-site_ok.jpg?h=601&w=900&hash=7DBEC2A8233BE4642B40D8C72C42753D'},
                {title: 'title 2', img:'https://c.files.bbci.co.uk/DDDA/production/_115149765_db4c2ec4-c6cf-418a-9a7f-1b43bb2ede25.jpg'},
                {title: 'title 3', img:'https://media.admagazine.com/photos/618a62eea1fa32def5822dc6/16:9/w_2560%2Cc_limit/77805.jpg'},
                {title: 'title 4', img:'https://www.kantar.com/latin-america/-/media/project/kantar/latin-america/inspiracion/ofrenda-site_ok.jpg?h=601&w=900&hash=7DBEC2A8233BE4642B40D8C72C42753D'},
                {title: 'title 5', img:'https://c.files.bbci.co.uk/DDDA/production/_115149765_db4c2ec4-c6cf-418a-9a7f-1b43bb2ede25.jpg'},
                {title: 'title 6', img:'https://media.admagazine.com/photos/618a62eea1fa32def5822dc6/16:9/w_2560%2Cc_limit/77805.jpg'},
            ]
        }
    }, 
    transport: {
        banner: {
            title:  'Creando nuevas conexiones con el Origen y Destino',
            infoSubtitle: `Buscamos mejorar la eficiencia, reducir costos, minimizar retrasos, y garantizar la entrega oportuna y precisa de 
                           productos a lo largo de la cadena de suministro, así contribuyendo a una mayor competitividad de las empresas, a 
                           la satisfacción de los clientes y a la optimización de los recursos.` ,
            imgBackgoundBanner: 'https://images.unsplash.com/photo-1611746351408-c0a1346be8e8?auto=format&fit=crop&q=80[…]3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            imgBanner: 'https://images.unsplash.com/photo-1659353741638-9bbe25cd8715?auto=format&fit=crop&q=80[…]3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },
        mision: {
            title: 'Mision',
            paragraph1: 'We’re a passionate group of people working from around the world to build the future of ecommerce.',
            paragraph2: `Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt
                        amet fugiat veniam occaecat fugiat aliqua. Anim aute id magna aliqua ad ad non deserunt sunt. Qui
                        irure qui lorem cupidatat commodo.`,
            paragraph3: 'Elit sunt amet fugiat veniam occaecat fugiat aliqua. Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
            stats: [
                    { label: 'Transactions every 24 hours', value: '44 million' },
                    { label: 'Assets under holding', value: '$119 trillion' },
                    { label: 'New users annually', value: '46,000' },
                ],
            img: 'https://images.unsplash.com/photo-1613690399151-65ea69478674?auto=format&fit=crop&q=80[…]3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
        },
        values: {
            title: 'Valores',
            subtitle: 'Los impulsores de nuestros lavores',
            infoValues: [
                {
                    name: 'Be world-class.',
                    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
                    icon: RocketLaunchIcon,
                },
                {
                    name: 'Take responsibility.',
                    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
                    icon: HandRaisedIcon,
                },
                {
                    name: 'Be supportive.',
                    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus voluptas blanditiis et.',
                    icon: UserGroupIcon,
                },
                {
                    name: 'Always learning.',
                    description: 'Iure sed ab. Aperiam optio placeat dolor facere. Officiis pariatur eveniet atque et dolor.',
                    icon: AcademicCapIcon,
                },
                {
                    name: 'Share everything you know.',
                    description: 'Laudantium tempora sint ut consectetur ratione. Ut illum ut rem numquam fuga delectus.',
                    icon: SparklesIcon,
                },
                {
                    name: 'Enjoy downtime.',
                    description: 'Culpa dolorem voluptatem velit autem rerum qui et corrupti. Quibusdam quo placeat.',
                    icon: SunIcon,
                },
            ]
        },
        solutions: {
            title: 'Soluciones',
            subtitle: 'Vision tec, soluciones en tiempo real',
            infoCards: [
                {title: 'title 1', img:'https://www.kantar.com/latin-america/-/media/project/kantar/latin-america/inspiracion/ofrenda-site_ok.jpg?h=601&w=900&hash=7DBEC2A8233BE4642B40D8C72C42753D'},
                {title: 'title 2', img:'https://c.files.bbci.co.uk/DDDA/production/_115149765_db4c2ec4-c6cf-418a-9a7f-1b43bb2ede25.jpg'},
                {title: 'title 3', img:'https://media.admagazine.com/photos/618a62eea1fa32def5822dc6/16:9/w_2560%2Cc_limit/77805.jpg'},
                {title: 'title 4', img:'https://www.kantar.com/latin-america/-/media/project/kantar/latin-america/inspiracion/ofrenda-site_ok.jpg?h=601&w=900&hash=7DBEC2A8233BE4642B40D8C72C42753D'},
                {title: 'title 5', img:'https://c.files.bbci.co.uk/DDDA/production/_115149765_db4c2ec4-c6cf-418a-9a7f-1b43bb2ede25.jpg'},
                {title: 'title 6', img:'https://media.admagazine.com/photos/618a62eea1fa32def5822dc6/16:9/w_2560%2Cc_limit/77805.jpg'},
            ]
        }
    },
    hospitality: {
        banner: {
            title:  'Creando Experiencias Innovadoras e Inolvidables',
            infoSubtitle: `Nuestro objetivo es ofrecer experiencias excepcionales a los huéspedes, optimizar la operación del negocio, aumentar 
                           la satisfacción del cliente y, en última instancia, impulsar la fidelidad de los visitantes, contribuyendo al éxito y
                           la competitividad en la industria de la hospitalidad.`,
            imgBackgoundBanner: 'https://images.unsplash.com/photo-1621293954908-907159247fc8?auto=format&fit=crop&q=80[…]3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
            imgBanner: 'https://www.fluentu.com/blog/english/wp-content/uploads/sites/4/2023/06/hotel-receptionist-giving-key-to-guest.jpg'
        },
        mision: {
            title: 'Mision',
            paragraph1: 'We’re a passionate group of people working from around the world to build the future of ecommerce.',
            paragraph2: `Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt
                        amet fugiat veniam occaecat fugiat aliqua. Anim aute id magna aliqua ad ad non deserunt sunt. Qui
                        irure qui lorem cupidatat commodo.`,
            paragraph3: 'Elit sunt amet fugiat veniam occaecat fugiat aliqua. Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
            stats: [
                    { label: 'Transactions every 24 hours', value: '44 million' },
                    { label: 'Assets under holding', value: '$119 trillion' },
                    { label: 'New users annually', value: '46,000' },
                ],
            img: 'https://digital.ihg.com/is/image/ihg/intercontinental-ciudad-de-mexico-9161779243-16x9'
        },
        values: {
            title: 'Valores',
            subtitle: 'Los impulsores de nuestros lavores',
            infoValues: [
                {
                    name: 'Be world-class.',
                    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
                    icon: RocketLaunchIcon,
                },
                {
                    name: 'Take responsibility.',
                    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
                    icon: HandRaisedIcon,
                },
                {
                    name: 'Be supportive.',
                    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus voluptas blanditiis et.',
                    icon: UserGroupIcon,
                },
                {
                    name: 'Always learning.',
                    description: 'Iure sed ab. Aperiam optio placeat dolor facere. Officiis pariatur eveniet atque et dolor.',
                    icon: AcademicCapIcon,
                },
                {
                    name: 'Share everything you know.',
                    description: 'Laudantium tempora sint ut consectetur ratione. Ut illum ut rem numquam fuga delectus.',
                    icon: SparklesIcon,
                },
                {
                    name: 'Enjoy downtime.',
                    description: 'Culpa dolorem voluptatem velit autem rerum qui et corrupti. Quibusdam quo placeat.',
                    icon: SunIcon,
                },
            ]
        },
        solutions: {
            title: 'Soluciones',
            subtitle: 'Vision tec, soluciones en tiempo real',
            infoCards: [
                {title: 'title 1', img:'https://www.kantar.com/latin-america/-/media/project/kantar/latin-america/inspiracion/ofrenda-site_ok.jpg?h=601&w=900&hash=7DBEC2A8233BE4642B40D8C72C42753D'},
                {title: 'title 2', img:'https://c.files.bbci.co.uk/DDDA/production/_115149765_db4c2ec4-c6cf-418a-9a7f-1b43bb2ede25.jpg'},
                {title: 'title 3', img:'https://media.admagazine.com/photos/618a62eea1fa32def5822dc6/16:9/w_2560%2Cc_limit/77805.jpg'},
                {title: 'title 4', img:'https://www.kantar.com/latin-america/-/media/project/kantar/latin-america/inspiracion/ofrenda-site_ok.jpg?h=601&w=900&hash=7DBEC2A8233BE4642B40D8C72C42753D'},
                {title: 'title 5', img:'https://c.files.bbci.co.uk/DDDA/production/_115149765_db4c2ec4-c6cf-418a-9a7f-1b43bb2ede25.jpg'},
                {title: 'title 6', img:'https://media.admagazine.com/photos/618a62eea1fa32def5822dc6/16:9/w_2560%2Cc_limit/77805.jpg'},
            ]
        }
    }
}