import { Fragment, useRef } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import {
    MagnifyingGlassIcon
} from '@heroicons/react/24/outline'
import { Link, useLocation } from 'react-router-dom'

export default function NavbarDropdown({type, name, link, data}) {

  const buttonRef = useRef();

  const {pathname} = useLocation()
  console.log(pathname)
  return (
    <Popover className="relative">
      <Popover.Button className={`inline-flex items-center gap-x-1 leading-6 ${type == 1 && pathname.includes('/vertical') ? "text-blue-900" : pathname === link ? "text-blue-900" : "text-gray-600"} text-sm md:text-base lg:text-lg`}>
        <span >{name}</span>
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-6 flex w-screen max-w-max -translate-x-1/2 px-4">
          <div className={`${type == 1 ? 'max-w-xs' : 'max-w-md' } w-screen flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5`}>
            <div className="p-4 divide-y divide-gray-200">


            <Popover.Button ref={buttonRef} hidden>Click me</Popover.Button>

                <Link to={link} onClick={() => buttonRef.current?.click()}>
                <div key="Info" className="px-4 py-2 group relative flex gap-x-6 rounded-lg mb-2 hover:bg-gray-50">
                  <div className={`${type == 1 ? 'h-9 w-9' : 'h-11 w-11' } mt-1 flex flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white`}>
                    <MagnifyingGlassIcon className={`${type == 1 ? 'group-hover:text-blue-600' : 'group-hover:text-red-600' } h-6 w-6 text-gray-600`} aria-hidden="true" />
                  </div>
                  <div className='place-self-center'>
                    <p className="text-[15px] font-semibold text-gray-900">
                      Más información
                      <span className="absolute inset-0" />
                    </p>
                  </div>
                </div>
                </Link>

                {type == 1 ? (
                    <div className='pt-2'>
                    {data.map((item) => (
                      <Link to={item.href} onClick={() => buttonRef.current?.click()}>
                        <div key={item.name} className="group relative flex gap-x-6 rounded-lg py-2 px-4 hover:bg-gray-50">
                          <div className="mt-1 flex h-9 w-9 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                            <item.icon className="h-6 w-6 text-gray-600 group-hover:text-blue-600" aria-hidden="true" />
                          </div>
                          <div className='place-self-center'>
                            <p className={`font-semibold text-gray-900 ${pathname === item.href ? "text-blue-900" : "text-gray-900"}`}>
                              {item.name}
                              <span className="absolute inset-0" />
                            </p>
                          </div>
                        </div>
                      </Link>
                      ))}
                    </div>
                ):(
                    <div className='pt-2'>
                         {data.map((item) => (
                          <Link to={item.href} onClick={() => buttonRef.current?.click()} state={item.linkRef}>
                            <div key={item.name} className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
                            <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                <item.icon className="h-6 w-6 text-gray-600 group-hover:text-red-600" aria-hidden="true" />
                            </div>
                            <div>
                              {console.log(data)}
                                <p className={`font-semibold text-gray-900`}>
                                {item.name}
                                <span className="absolute inset-0" />
                                </p>
                                <p className="mt-1 text-gray-600">{item.description}</p>
                            </div>
                            </div>
                          </Link>
                        ))}
                    </div>
                )}



            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
