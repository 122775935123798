
import './App.css';
import { useState } from 'react';
import Footer from './Components/Elements/Footer';
import Navbar from './Components/Elements/Navbar';
import NavbarEcom from './Components/Elements/NavbarEcom';
import LandingPage from './Components/Pages/LandingPage';
import SolutionsPage from './Components/Pages/SolutionsPage';
import { Route, Routes } from 'react-router-dom';
import ServicesPage from './Components/Pages/ServicesPage';
import VerticalInfoPage from './Components/Pages/VerticalInfoPage';
import SuccessStoryPage from './Components/Pages/SuccessStoryPage';
import SWRYApage from './Components/Pages/SWRYApage';
import AboutUsPage from './Components/Pages/AboutUsPage';
import ContactPage from './Components/Pages/ContactPage';
import PrivacyNoticePage from './Components/Pages/PrivacyNoticePage';

function App() {

  return (
    <div>
      <Navbar/>
      
      <Routes>

      <Route path="/" element={<LandingPage/>}/>
      <Route path="/solutions" element={<SolutionsPage/>}/>
      <Route path="/services" element={<ServicesPage/>}/>
      <Route path="/vertical/:type" element={<VerticalInfoPage/>}/>
      <Route path="/story/:type" element={<SuccessStoryPage/>}/>
      <Route path="/swrya" element={<SWRYApage/>}/>
      <Route path="/aboutUs" element={<AboutUsPage/>}/>
      <Route path="/contact" element={<ContactPage/>}/>
      <Route path="/privacy" element={<PrivacyNoticePage/>}/>
    
      </Routes>

      <Footer/>    
    </div>
  );
}

export default App;
