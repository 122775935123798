import React, { createRef, useEffect, useLayoutEffect, useRef } from 'react'
import HeroSection from '../Elements/HeroSection'
import Foto_1 from '../../Media/soluciones1.jpg'
import Foto_2 from '../../Media/soluciones2.jpg'
import Foto_3 from '../../Media/soluciones3.jpg'
import almacIcon from '../../Media/almacenIcon.png'
import hotelIcon from '../../Media/hotelIcon.png'
import retailIcon from '../../Media/retailIcon.png'
import rutaIcon from '../../Media/rutaIcon.png'
import manuIcon from '../../Media/manufacturaIcon.png'
import BtnSolutionNav from '../Elements/BtnSolutionNav'
import SolutionSection from '../Elements/SolutionSection'
import NavbarSol from '../Elements/NavbarSol'
import { data } from '../../Data/infoSolutionsPage'

export default function SolutionsPage() {

    useEffect(() => {
        window.scrollTo(0, 0)
        sectionRef.current = data.section.map((_, i) => sectionRef.current[i] ?? createRef());
      }, [])

    const learnMoreRef = useRef(null);
    const sectionRef = useRef([]);

    const handleLearnMore = () => {
        learnMoreRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
      
  return (
    <div className=''>
        
        <HeroSection handleClick={handleLearnMore} title={'Industrias'} subtitle={"Justo lo que estabas buscando"} text={'Te brindamos enfoques, tecnologías y estrategias personalizadas para atender las necesidades y desafíos específicos de tu industria. '} img1={Foto_1} img2={Foto_2} img3={Foto_3}/>

        {/* BOTONES NAV SOLUCIONES*/}
        <div ref={learnMoreRef}/>
        <div className='w-full h-auto'>
            <div className='w-3/4 ml-auto mr-auto pt-16 pb-20
            sm:pb-24
            lg:w-2/3 lg:pb-28'>
                <div className='mb-14 tracking-wide italic text-zinc-700 text-lg
                lg:text-2xl'>
                Ofrecemos soluciones para las siguientes verticales:
                </div>

                 <div className='w-full grid grid-cols-2 place-items-center gap-x-5
                 gap-y-14 text-zinc-600
                 sm:grid-cols-5'>

                    <div onClick={() => {
                        sectionRef.current[0]?.scrollIntoView({ behavior: 'smooth' });}}>
                    <BtnSolutionNav icon={almacIcon} name={'almacenes'}/>
                    </div>

                    <div onClick={()=>{
                        sectionRef.current[1]?.scrollIntoView({ behavior: 'smooth' });}}>
                    <BtnSolutionNav icon={manuIcon} name={'manufactura'}/>
                    </div>

                    <div onClick={()=>{
                        sectionRef.current[2]?.scrollIntoView({ behavior: 'smooth' });}}>
                    <BtnSolutionNav icon={retailIcon} name={'retail'}/>
                    </div>

                    <div onClick={()=>{
                        sectionRef.current[3]?.scrollIntoView({ behavior: 'smooth' });}}>
                    <BtnSolutionNav icon={rutaIcon} name={["transporte", <div>& logística</div>]}/>
                    </div>

                    <div onClick={()=>{
                        sectionRef.current[4]?.scrollIntoView({ behavior: 'smooth' });}}>
                    <BtnSolutionNav icon={hotelIcon} name={'hospitalidad'}/>
                    </div>

                </div>
            </div>
        </div>


        {/* SECCIONES/VERTICALES */}

        <div className='relative h-auto'>

            <div className='absolute h-full'>
            <div className='sticky top-0 left-0 h-screen hidden lg:flex  
                    items-center z-40'>
            <NavbarSol handleRef={sectionRef} />
            </div>
            </div>

            {data.section.map((data , i)=>(
                <div key={data.value} ref={ref => sectionRef.current[i] = ref}>
                <SolutionSection rightSide={i%2 == 0 ? true : false} data={data}/>
                </div>
            ))}

        </div>


        <div className='h-auto flex justify-center mb-20 mt-20 md:mt-28'>
            <button className='w-56 h-12 border rounded-full text-lg text-zinc-500 border-zinc-500
            transition duration-200 ease-in-out hover:bg-zinc-500 hover:text-white'
            onClick={()=>{
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            }}>Regresar al principio</button>
        </div>


    </div>
  )
}
