import React, { useState } from 'react'
import LogoAzul from '../../Media/LogoAzul.png'
import { dropdownData } from '../../Data/infoNavbarDropdown'
import { Dialog, Disclosure } from '@headlessui/react'
import { XMarkIcon, ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'


export default function NavbarSmResp({mobileMenuOpen, setMobileMenuOpen}) {

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }

    const [disclosureState, setDisclosureState] = useState(0);

    function handleDisclosureChange(state) {
    if (state === disclosureState) {
        setDisclosureState(0); // close all of them
    } else {
        setDisclosureState(state); // open the clicked disclosure
    }
    }
    

  return (
    <Dialog as="div" className="sm:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-[60]" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-[60] w-full overflow-y-auto bg-white px-6 py-4 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to={'/'} onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">Your Company</span>
              <img
              src={LogoAzul}
              alt='Logo'
              placeholder='blur'
              className='w-32'
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md mr-2 p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button 
                      onClick={() => handleDisclosureChange(1)} 
                      className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-8 text-base leading-7 text-gray-900 hover:bg-gray-50">
                        Industrias
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                        <Disclosure.Panel className="mt-2">
                            {({ close }) =>
                                disclosureState == 1 ? (
                                    <>
                                    <div className="divide-y divide-gray-100">
                                    <Link to={'/solutions'} onClick={() => setMobileMenuOpen(false)}>
                                    <Disclosure.Button
                                        key="Más Información"
                                        as="a"
                                        className="group flex items-center rounded-lg mb-2 py-3 pl-6 pr-3 text-sm leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                        <MagnifyingGlassIcon className="h-6 w-6 mr-5 text-gray-600 group-hover:text-blue-600" aria-hidden="true" />
                                        Más Información
                                    </Disclosure.Button>
                                    </Link>
                                    <div className='pt-2 space-y-2'>
                                    {dropdownData.Industries.map((item) => (
                                        <Link to={item.href} onClick={() => setMobileMenuOpen(false)}>
                                        <Disclosure.Button
                                            key={item.name}
                                            as="a"
                                            className="group flex items-center rounded-lg py-3 pl-6 pr-3 text-sm leading-7 text-gray-900 hover:bg-gray-50"
                                            >
                                            <item.icon className="h-6 w-6 mr-5 text-gray-600 group-hover:text-blue-600" aria-hidden="true" />
                                            {item.name}
                                        </Disclosure.Button>
                                        </Link>
                                        ))}
                                    </div>
                                    </div>
                                    </>
                                ) : (
                                  close()
                                )
                              }
                        </Disclosure.Panel>
                      
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                      onClick={() => handleDisclosureChange(2)} 
                      className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-8 text-base leading-7 text-gray-900 hover:bg-gray-50">
                        Servicios
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                        <Disclosure.Panel className="mt-2">
                            {({ close }) =>
                                disclosureState == 2 ? (
                                    <>
                                    <div className="divide-y divide-gray-100">
                                    <Link to={'/services'} onClick={() => setMobileMenuOpen(false)}>
                                    <Disclosure.Button
                                        key="Más Información"
                                        as="a"
                                        className="group flex items-center rounded-lg mb-2 py-3 pl-6 pr-3 text-sm leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        <MagnifyingGlassIcon className="h-6 w-6 mr-5 text-gray-600 group-hover:text-red-600" aria-hidden="true" />
                                        Más Información
                                    </Disclosure.Button>
                                    </Link>
                                    <div className='pt-2 space-y-2'>
                                    {dropdownData.Services.map((item) => (
                                        <Link to={item.href} onClick={() => setMobileMenuOpen(false)} state={item.linkRef}>
                                        <Disclosure.Button
                                            key={item.name}
                                            as="a"
                                            className="group flex items-center rounded-lg py-3 pl-6 pr-3 text-sm leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            <item.icon className="h-6 w-6 mr-5 text-gray-600 group-hover:text-red-600" aria-hidden="true" />
                                            {item.name}
                                        </Disclosure.Button>
                                        </Link>
                                        ))}
                                    </div>
                                    </div>
                                    </>
                                ) : (
                                  close()
                                )
                              }
                        </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                {/* <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Marketplace
                </a>
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Company
                </a> */}
              </div>
              <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Buscar
                </a>
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Soporte
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
  )
}
