import React from 'react'
import TiktokPreview from './TiktokPreview'
import { Link } from 'react-router-dom'

export default function SolutionSection({rightSide, data}) {
  return (
    <div className=''>
      {rightSide == true ? (

        <div className={`h-[700px] sm:h-[750px] lg:h-[825px] ${data.bgColor} flex`}>
            <div className="relative w-1/4 flex
            sm:w-2/5 
            2xl:w-1/2">
              <div className='w-full bg-cover'
              //style={{backgroundImage : `url(${Hotel})`}}
              style = {{backgroundImage: `linear-gradient(to left, rgba(0, 0, 0, 0), ${data.gradient} 80%), 
              url(${data.image})`}}/>

            </div>

            <div className='w-3/4 flex justify-center 
            sm:w-3/5 sm:justify-start
            2xl:w-1/2'>
                <div className='max-w-md content-center grid grid-cols-1 text-zinc-700 space-y-9 px-8
                sm:space-y-6 sm:px-12
                xl:space-y-10 xl:max-w-xl
                2xl:pl-20'>
                    <p className='text-4xl font-semibold
                    sm:text-4xl 
                    xl:text-5xl
                    2xl:text-6xl'>{data.title}</p>
                    <p className='text-sm leading-7
                    sm:leading-8 sm:text-[15px]
                    xl:text-base xl:leading-9
                    2xl:text-lg 2xl:leading-10'> {data.description} </p>
                    <div className='grid grid-cols-2 gap-6'>
                      <Link to={`/vertical/${data.value}`} >
                      <button className='w-full h-12 border-2 rounded-[22px] text-sm text-zinc-500 border-zinc-500 font-medium
                      sm:text-[15px] sm:h-14
                      xl:text-lg lg:h-16
                      transition duration-300 ease-in-out hover:bg-zinc-500 hover:text-white'>Más información</button>
                      </Link>
                      {/* <button className='w-full h-12 border-2 rounded-[22px] text-sm text-zinc-500 border-zinc-500 font-medium
                      sm:text-[15px] sm:h-14
                      xl:text-lg xl:h-16
                      transition duration-300 ease-in-out hover:bg-zinc-500 hover:text-white'>¡Comienza ya!</button> */}
                    </div>
                </div>
            </div>
        </div>



      ) : (



        <div className={`h-[700px] sm:h-[750px] lg:h-[825px] ${data.bgColor} flex`}>
            <div className='w-3/4 flex justify-center 
            sm:w-3/5 sm:justify-end
            2xl:w-1/2'>
                <div className='max-w-md content-center grid grid-cols-1 text-zinc-700 space-y-9 px-8
                sm:space-y-6 sm:px-12
                xl:space-y-10 xl:max-w-xl
                2xl:pr-20'>
                    <p className='text-4xl font-semibold
                    sm:text-4xl 
                    xl:text-5xl
                    2xl:text-6xl'>{data.title}</p>
                    <p className='text-sm leading-7
                    sm:leading-8 sm:text-[15px]
                    xl:text-base xl:leading-9
                    2xl:text-lg 2xl:leading-10'> {data.description} </p>
                    <div className='grid grid-cols-2 gap-6'>
                      <Link to={`/vertical/${data.value}`} >
                      <button className='w-full h-12 border-2 rounded-[22px] text-sm text-zinc-500 border-zinc-500 font-medium
                      sm:text-[15px] sm:h-14
                      xl:text-lg xl:h-16
                      transition duration-300 ease-in-out hover:bg-zinc-500 hover:text-white'>Más información</button>
                      </Link>
                      {/* <button className='w-full h-12 border-2 rounded-[22px] text-sm text-zinc-500 border-zinc-500 font-medium
                      sm:text-[15px] sm:h-14
                      xl:text-lg xl:h-16
                      transition duration-300 ease-in-out hover:bg-zinc-500 hover:text-white'>¡Comienza ya!</button> */}
                    </div>
                </div>
            </div>

            <div className="relative w-1/4 flex
            sm:w-2/5 
            2xl:w-1/2">
              <div className='w-full bg-cover'
              //style={{backgroundImage : `url(${Hotel})`}}
              style = {{backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0), ${data.gradient} 80%), 
              url(${data.image})`}}/>


            </div>
        </div>
      )}
    </div>
  )
}
