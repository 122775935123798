import React from 'react'

export default function ServiceTab ({id, icon, name, initialState, setClicked, clickedTab}){

    return(
        <div className={`${clickedTab == true ? 
            id == 'control' ? 'bg-red-300' : 
            id == 'audit' ? 'bg-orange-300' :
            id == 'credencial' ? 'bg-green-300' : 
            id == 'poliza' ? 'bg-blue-300' : 'bg-purple-300' : 'bg-zinc-200 border-l-[6px] border-zinc-400 cursor-pointer sm:border-l-[0px] sm:border-b-[6px]'} 
            flex flex-row items-center pt-5 pb-5
            sm:flex-col sm:pt-4 sm:pb-3
            lg:pt-6 lg:pb-5`}
        onClick={()=>{
          window.scrollTo({top: 1407, left: 0, behavior: 'smooth'});
            switch(id){
              case "control":
                setClicked(({...initialState, control: true}))
                break;
              case "audit":
                setClicked(({...initialState, audit: true}))
                break;
              case "credencial":
                setClicked(({...initialState, credencial: true}))
                break;
              case "poliza":
                setClicked(({...initialState, poliza: true}))
                break;
              default:
                setClicked(({...initialState, software: true}))
                break;
            }
          }}>
            <div className='block w-14 h-14 mt-auto mb-auto ml-6 mr-4
            flex-none 
            sm:mt-[0px] sm:w-16 sm:w-16 sm:mb-4
            lg:mb-3 lg:w-20 lg:h-20 lg:mx-[0px]
            '>
            {/* 2xl:mb-6 2xl:w-32 2xl:h-32 */}
                <img
                src={icon} alt='Icon'
                className={`${clickedTab == true ? 'opacity-100' : 'opacity-30'} object-cover`}
                />
            </div>
            <div className={`${clickedTab == true ? 'text-white [text-shadow:_2px_2px_3px_rgb(0_0_0_/_50%)]' : 'text-zinc-500'} text-left text-lg tracking-[.015em] leading-6
            sm:text-center sm:text-xs sm:leading-4
            lg:text-base lg:leading-5
            2xl:text-lg 2xl:leading-6 2xl:px-5
            `}>
                {name}</div>
        </div>
    )
}
