export const data = {
    case1: {
        value: 'case1' ,
        heroSection: {
            title: 'La Belleza en Logística',
            text: '"Códigos de barras: el pilar tecnológico que redefine la cadena de suministro en la industria de la higiene personal.',
            img:'https://images.unsplash.com/photo-1631730486572-226d1f595b68?q=80&w=1975&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        },

        information: {
            title: 'Titulo #1',
            subtitle: 'Subtitle #1',
            paragraph1:'En el núcleo de la manufactura de higiene personal y limpieza, los códigos de barras emergen como el pilar tecnológico que está redefiniendo la cadena de suministro. Aunque no sea una novedad, su implementación estratégica significa un avance significativo hacia la eficiencia operativa. Los equipos de códigos de barras, recomendados por visiontec son altamente sofisticados y robustos, garantizan un rendimiento duradero incluso en los entornos más exigentes. ',
            paragraph2:'Desde el minucioso análisis de la red hasta la instalación y configuración experta de equipos, cada paso se enfoca en optimizar la gestión de inventario y el seguimiento de productos. Estos códigos, estandarizados y omnipresentes, no solo reducen los errores, sino que también agilizan las operaciones, creando una sinergia fluida entre la producción y el almacenamiento.',
            paragraph3:' En un mundo donde la precisión y la rapidez son imperativas, los códigos de barras se erigen como la columna vertebral tecnológica que impulsa la excelencia en la industria. Gracias a la eficiencia en los almacenes, podemos disfrutar de una distribución de productos esenciales para nuestros hogares.',
            url: 'https://latam.pg.com/',

            statistics: [
                {label: 'Fundado', value: '2021'},
                {label: 'Empleados', value: '37'},
                {label: 'Países', value: '12'},
                {label: 'Recaudado', value: '$25M'}
            ],

            statement: {
                logo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/P%26G_logo.png/800px-P%26G_logo.png',
                text:'Del Almacén a tu Hogar: Cómo los Códigos de Barras Garantizan la Entrega de Productos Esenciales',
                author:'Duck Dodgers',
                dept:'CEO at Workcation',
                bgImg:'https://images.pexels.com/photos/6169659/pexels-photo-6169659.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
            }
        }
    },



    case2: {
        value: 'case2',
        heroSection: {
            title: 'Revolución de la Gestión Pesquera',
            text: 'Una colaboración transformadora: Implementación de tecnología RFID para el control preciso del almacenamiento de productos marinos.',
            img:'https://www.grupopinsa.mx/assets/images/template/pesca-azteca.png',
        },

        information: {
            title: 'Titulo #2',
            subtitle: 'Subtitle #2',
            paragraph1:'En un esfuerzo por mejorar la eficiencia y la transparencia en su cadena de suministro, diversas empresas han dado un paso audaz al adoptar la tecnología de Identificación por Radiofrecuencia (RFID) para la trazabilidad de sus tinas de pescado. La colaboración con líderes en soluciones de software y equipos de radiofrecuencia ha sido clave en la implementación exitosa de esta innovadora iniciativa.',
            paragraph2:'La introducción de etiquetas RFID en las tinas de pescado ha permitido rastrear cada unidad a lo largo de toda la cadena de producción y distribución. Desde la captura en alta mar hasta la entrega al cliente, cada paso se registra de manera precisa y en tiempo real. Esto no solo ha optimizado la gestión logística, sino que también ha fortalecido el compromiso con la trazabilidad y la sostenibilidad.',
            paragraph3:'La tecnología RFID ha demostrado ser una herramienta invaluable para mejorar la calidad del producto. Con la capacidad de monitorear las condiciones de almacenamiento y transporte, las empresas pueden garantizar que sus productos lleguen a los consumidores en las condiciones ideales, manteniendo la frescura y la integridad. Además, esta trazabilidad detallada brinda la capacidad de responder rápidamente ante cualquier problema potencial, mejorando la toma de decisiones y la respuesta a las demandas del mercado.',
            url: 'https://www.grupopinsa.mx/',

            statistics: [
                {label: 'Fundado', value: '2021'},
                {label: 'Empleados', value: '37'},
                {label: 'Países', value: '12'},
                {label: 'Recaudado', value: '$25M'}
            ],

            statement: {
                logo: 'https://www.grupopinsa.mx/assets/images/template/logo-pinsa.png',
                text:'La implementación de la trazabilidad de tinas de pescado con RFID ha demostrado ser un catalizador para la eficiencia operativa y la mejora de la calidad en diversas industrias.',
                author:'Duck Dodgers',
                dept:'CEO at Workcation',
                bgImg:'https://www.grupopinsa.mx/assets/images/template/pinsa.jpg'
            }
        }
    },



    case3: {
        value: 'case3',
        heroSection: {
            title: 'Empaques con Impacto',
            text: 'La implementación de la tecnología RFID en los rollos de productos para empaque ha beneficiado la gestión logística.',
            img:'https://images.unsplash.com/photo-1600009723489-027195d6b3d3?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        },

        information: {
            title: 'Titulo #3',
            subtitle: 'Subtitle #3',
            paragraph1:'En el dinámico sector de fabricación y comercialización de productos para empaque y embalaje, una transformación significativa ha tenido lugar gracias a la adopción de tecnología de Identificación por Radiofrecuencia (RFID) en los rollos de productos. Una colaboración clave entre líderes en equipos de radiofrecuencia y soluciones de software ha impulsado esta innovadora iniciativa.',
            paragraph2:'La implementación de la tecnología RFID en los rollos de productos ha habilitado una trazabilidad precisa en cada etapa, desde la fabricación hasta la distribución. Cada rollo se identifica y monitorea en tiempo real, mejorando considerablemente la eficiencia logística y proporcionando datos valiosos sobre la ubicación y estado de cada producto.',
            paragraph3:'Este enfoque no solo ha optimizado la gestión logística, sino que también ha elevado la calidad del servicio al cliente. La capacidad de rastrear y monitorear individualmente cada rollo permite garantizar entregas precisas y mantener altos estándares de calidad en productos que abarcan desde cartón de una sola cara hasta diversos tipos de papel y cartón para empaque y embalaje.',
            url: 'http://delhuajuco.com/',

            statistics: [
                {label: 'Fundado', value: '2021'},
                {label: 'Empleados', value: '37'},
                {label: 'Países', value: '12'},
                {label: 'Recaudado', value: '$25M'}
            ],

            statement: {
                logo: 'https://rimg.cosmos.com.mx/R/min/4jbzcolo.png',
                text:'La estratégica colaboración en la implementación de rollos RFID refleja el compromiso continuo con la excelencia y establece un estándar más alto en términos de trazabilidad y calidad en productos para empaque.',
                author:'Duck Dodgers',
                dept:'CEO at Workcation',
                bgImg:'https://images.unsplash.com/photo-1603189840870-7ea4dc14a816?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
            }
        }
    },


    case4: {
        value: 'case4',
        heroSection: {
            title: 'Administración Eficiente',
            text: 'Innovación en la operación para establecer un control integral del inventario.',
            img:'https://images.unsplash.com/photo-1426927308491-6380b6a9936f?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        },

        information: {
            title: 'Titulo #3',
            subtitle: 'Subtitle #3',
            paragraph1:'En el ámbito de la comercialización de herramientas para diversos sectores industriales y profesionales, una transformación significativa ha tomado forma gracias a la colaboración estratégica entre un especialista en equipos de radiofrecuencia y soluciones de software y una empresa líder en este sector.',
            paragraph2:'El aporte clave se materializa en el control total del almacén mediante la tecnología de Identificación por Radiofrecuencia (RFID). Desde el seguimiento preciso de pedidos hasta el eficiente surtimiento, acomodo, y gestión de entradas y salidas, esta innovación ha optimizado de manera notable la operación logística.',
            paragraph3:'La implementación de RFID ha brindado una visibilidad en tiempo real de cada herramienta y producto en el almacén, mejorando la precisión en los pedidos y reduciendo los tiempos de respuesta. Este enfoque tecnológico ha fortalecido la capacidad para satisfacer las demandas del mercado de manera eficiente, manteniendo al mismo tiempo la integridad del inventario.',
            url: 'http://delhuajuco.com/',

            statistics: [
                {label: 'Fundado', value: '2021'},
                {label: 'Empleados', value: '37'},
                {label: 'Países', value: '12'},
                {label: 'Recaudado', value: '$25M'}
            ],

            statement: {
                logo: 'https://http2.mlstatic.com/D_NQ_NP_628088-MLM44087442312_112020-O.webp',
                text:'La coalición estratégica permitió dar un salto cuántico hacia un control total del almacén y redefinir la manera en que se abordan los procesos',
                author:'Duck Dodgers',
                dept:'CEO at Workcation',
                bgImg:'https://images.unsplash.com/photo-1597423498219-04418210827d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
            }
        }
    }
}